import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function Services() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Services</title>
                            <meta id="meta-description" name="description" content="Tenant Agreement offers a variety of services for landlords including checked
                                downloadable Tenancy Agreements, Guarantor Forms, Eviction Notice Forms and more
                                " />
                                <meta id="meta-keywords" name="keywords" content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                download tenancy agreement, buy a tenancy agreement, buy a tenancy agreement online,
                                checked tenancy agreement, Eviction Notices, Landlord Deposit Scheme, Guarantor Form,
                                Tenant Guarantor Form
                                " />
                            <meta id="og-title" property="og:title" content="Services" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>
                                                Landlord Pack
                                                <span className="pull-right"><Link to="/order-now/landlord_pack.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>A completed Tenancy Agreement that clearly sets out your and your
                                                tenant(s) responsibilities, checked for discrepancies, drafted for you
                                                and sent to you within 24 hours and all of the documentation that you
                                                will need to ensure that you adhere to the legal requirements placed
                                                upon you as a landlord.</p>

                                            <p>Our Landlord Pack includes:</p>
                                            <ul>
                                                <li>A Tenant Guarantor Form – to protect you from lost rent, damage to
                                                    your property and any legal fees accrued by your tenant
                                                </li>
                                                <li>A Tenant Deposit Scheme Notice Form – to ensure that you comply with
                                                    the Tenancy
                                                    Deposit Scheme (TDS) legislation
                                                </li>
                                                <li>An S8 Eviction Form – to allow you to legally evict a tenant if they
                                                    breach a term of your agreement such as the payment of rent
                                                </li>
                                                <li>An S21 Eviction Form – to allow you to legally repossess a property
                                                    following the fixed tenancy term having expired
                                                </li>
                                                <li>An Inventory Form</li>
                                                <p>Our Landlord Pack includes everything that you will need to rent
                                                    your property safe in the knowledge that you have adhered to all
                                                    legislation for <b>just £19.99!</b></p>
                                            </ul>

                                            <legend>
                                                Download Service
                                                <span className="pull-right">
                                                    <Link to="/order-now/download_service.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>


                                            <p>
                                                A completed Tenancy Agreement that clearly sets out your and your
                                                tenant(s) responsibilities prepared online, and then <b>sent to you
                                                instantly</b>.
                                            </p>
                                            <p>
                                                Whilst there is no legal requirement for landlords to provide their
                                                tenants with a written agreement – and you may, instead, rely on verbal
                                                agreements reached between you and your tenant(s) – failing to do so
                                                can cause significant problems, and lead to you accruing <b>unnecessary
                                                legal costs</b>, in the event of a dispute.
                                            </p>
                                            <p>
                                                You won’t need to instruct a solicitor or understand complex legal
                                                jargon, you simply need a few minutes in order to complete a short,
                                                straightforward online form – <b>a concise, clearly written and legally
                                                binding Tenancy Agreement</b> will then be completed for you online
                                                and made available via download or sent to you by email.
                                            </p>
                                            <p>
                                                With our Tenancy Agreement you can enjoy the <b>peace of mind</b> that
                                                comes from knowing that you’re covered in the event of a dispute
                                                between yourself and your tenant(s) – <b>and all for just £9.99!</b>
                                            </p>


                                            <legend>
                                                Lodger Agreement
                                                <span className="pull-right"><Link to="/order-now/lodger_agreement.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>A Lodger Agreement is a written or verbal agreement between a
                                                resident landlord and the lodger(s) that also live in their property.
                                                Whilst there is no legal requirement for you to obtain such an
                                                agreement and renting a room in a property within which you reside is
                                                simpler than renting out an entire property, it is still advisable that
                                                you obtain a Lodger Agreement which specifies:</p>
                                            <ul>
                                                <li>The duration of the let (how long your lodger will be entitled to
                                                    reside in the relevant room);
                                                </li>
                                                <li>How much rent is payable and the frequency of these payments;</li>
                                                <li>Details of any deposits that are to be collected;</li>
                                                <li>An inventory of the items present within the relevant room and
                                                    their condition; and
                                                </li>
                                                <li>Any additional services that the landlord may provide to their
                                                    lodger(s) such as cleaning.
                                                </li>
                                            </ul>
                                            <p>Failing to obtain such an agreement can cause significant problems,
                                                and lead to you accruing unnecessary legal costs, in the event of a
                                                dispute. Fortunately, with a concise, clearly written and binding
                                                Lodger Agreement you can enjoy the peace of mind that comes from
                                                knowing that you’re covered in the event of a dispute between yourself
                                                and your lodger(s) - <b>and all for just £9.99!</b></p>


                                            <legend>
                                                Personal Service
                                                <span className="pull-right"><Link to="/order-now/checked_service.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>
                                                A completed Tenancy Agreement that clearly sets out your and your
                                                tenant(s) responsibilities, <b>checked for discrepancies</b>, drafted
                                                for you and <b>sent to you within 24 hours</b>.
                                            </p>
                                            <p>
                                                Whilst there is no legal requirement for landlords to provide their
                                                tenants with a written agreement – and you may, instead, rely on verbal
                                                agreements reached between you and your tenant(s) – failing to do so
                                                can cause significant problems, and lead to you accruing <b>unnecessary
                                                legal costs</b>, in the event of a dispute.
                                            </p>
                                            <p>You won’t need to instruct a solicitor or understand complex legal
                                                jargon, you simply need a few minutes in order to complete a short,
                                                straightforward online form – your Tenancy Agreement will then be
                                                completed for you, checked by an experienced case manager and made
                                                available via download or sent to you by email within 24 hours.</p>
                                            With our checked Tenancy Agreement you can enjoy the
                                            <b>peace of mind</b>
                                            that comes from knowing that you’re covered in the event of a dispute
                                            between yourself and your tenant(s) –
                                            <b>and all for just £14.99!</b>


                                            <legend>
                                                Landlord Inventory Form
                                                <span className="pull-right"><Link to="/order-now/inventory.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>Also known as a Schedule of Condition, an Inventory Form is used to
                                                outline all of the items contained within a property as well as their,
                                                and the property’s condition, prior to a tenancy commencing.</p>
                                            <p>
                                                Without an Inventory Form that has been signed by your tenant, it can
                                                be extremely difficult to <b>prove that your tenant has damaged your
                                                property</b> or any items contained within it, thus making it
                                                difficult for you to <b>charge them for any repairs or replacements</b>.
                                            </p>
                                            <p>
                                                Tenancy Agreement can provide you with a template Inventory Form so you
                                                can rest assured that the condition of both your property and the items
                                                contained within are well documented prior to a tenancy commencing and
                                                that you’ll never be left out of pocket as a result of damage caused by
                                                your tenants as a result – <b>all just £4.99!</b>
                                            </p>

                                            <legend>
                                                A Tenant Guarantor Form
                                                <span className="pull-right"><Link to="/order-now/guarantor_form.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>
                                                Whilst you may be able to recover any losses that you suffer as a
                                                result of your tenant damaging your property or failing to provide you
                                                with rent, claiming what you are owed from these individuals can prove
                                                difficult and it is advisable that you obtain the <b>additional
                                                safety</b>
                                                afforded to you through a Guarantor Form as a result.
                                            </p>

                                            <p>
                                                With a Guarantor Form you have the <b>added security</b> of knowing
                                                that it is not only your tenant but also another individual – such as
                                                your tenant’s friend or relative – that is <b>liable for any loss that
                                                you may suffer</b>, thus enabling you to claim any losses that you
                                                suffer as a result of your tenant’s action from this individual
                                                providing you with <b>greater confidence and peace of mind</b> – <b>and
                                                all for just £4.99!</b>
                                            </p>

                                            <legend>
                                                A TDS Notice Form
                                                <span className="pull-right"><Link to="/order-now/tds_notice_form.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>
                                                Any deposit that you collect from your tenants <b>must be protected</b>.
                                                Furthermore, you must inform your tenant of how you have protected
                                                their deposit within 30 days of you having received it. Failing to do
                                                so will not only prevent you from regaining possession of your property
                                                following your agreement having expired, but is also likely to result
                                                in you being issued with a <b>substantial fine</b>.
                                            </p>

                                            <p>
                                                Tenancy Agreement can provide you with a Tenancy Deposit Protection
                                                Form (also known as a Section 213 Notice) – which details the deposit
                                                scheme that you are using, how your tenant can apply for their deposit
                                                to be released and what to do in the event of a dispute regarding the
                                                deposit – along with <b>detailed guidance notes</b> to help you
                                                complete the form correctly.
                                            </p>

                                            <p>
                                                With our Tenancy Deposit Protection form you can be sure you’ve <b>complied
                                                with the relevant legislation</b> thus ensuring that you’re able to,
                                                if necessary, evict your tenant and avoid unnecessary fines – <b>and
                                                all for just £4.99</b>!
                                            </p>

                                            <legend>
                                                An Eviction Notice Form
                                                <span className="pull-right"><Link to="/order-now/eviction_form.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <h4>
                                                Section 8 Eviction Notice
                                            </h4>
                                            <p>
                                                If your tenant has failed to pay you rent, damaged your property or
                                                breached your Tenancy Agreement in any other way, then <b>you are
                                                entitled to terminate their tenancy irrespective of the fixed term
                                                stated within your agreement.</b>
                                            </p>

                                            <p>
                                                In order to ensure that you are able to repossess your property as
                                                swiftly as possible, however, you must <b>ensure that you serve your
                                                tenant</b> with a Section 8 Eviction Notice and that this document
                                                clearly <b>states the precise breach that has taken place.</b> Get your
                                                Section 8 Eviction Notice and comprehensive guide detailing how to
                                                complete it
                                                <b>for just £9.99!</b>
                                            </p>

                                            <h4>
                                                Section 21 Eviction Notice
                                            </h4>

                                            <p>
                                                Provided you have the right documentation and know how to utilise it,
                                                then terminating a tenancy when the fixed term has expired or is close
                                                to expiration is a straightforward process. Without this, however, the
                                                process of evicting a tenant can be <b>both time consuming and
                                                expensive</b>.
                                            </p>

                                            <p>
                                                In order to ensure that you are able to <b>regain possession of your
                                                property as quickly as possible</b>, you must provide your tenant with
                                                a Section 21 Eviction Notice that is correctly written, provides your
                                                tenant with the minimum notice period required by law, and is addressed
                                                to
                                                the correct individual. Get your Section 21 Eviction Notice and
                                                comprehensive guide
                                                detailing how to complete it for <b>just £9.99!</b>
                                            </p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="./images/call-center.jpg" />
                                            <div className="caption">
                                                <h3>
                                                    Service
                                                </h3>
                                                <p>
                                                    We are dedicated to ensuring that each of our customers enjoys a
                                                    superior service. All of our processes and documents have been
                                                    rigorously reviewed in order to ensure their accuracy and validity.

                                                    We also provide all of our customers with free phone and email
                                                    support for any queries that they may have concerning any of our
                                                    products.
                                                </p>
                                            </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default Services;