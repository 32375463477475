import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function Guarantee() {

    return (
        <div className="container">
            <div className="row-fluid">
                <MetaTags>
                    <title>Tenant Agreement's Low-Cost Guarantee</title>
                    <meta id="meta-description" name="description"
                          content="A Tenancy Agreement cannot be found for less with Tenant Agreement's Guarantee"/>
                    <meta id="meta-keywords" name="keywords"
                          content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                How Much is a Tenancy Agreement, How Much does a Tenancy Agreement Cost, Best Deal for
                                Tenancy Agreements, Cheap Tenancy Agreement"/>
                    <meta id="og-title" property="og:title" content="Tenant Agreement's Low-Cost Guarantee"/>
                </MetaTags>
                <div className="span12 content-container">
                    <div className="row-fluid">
                        <div id="content" className="span8">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block about_text-block__1Un4d">
                                    <div className="caption">
                                        <legend>Guarantee</legend>

                                        <p>We believe we have developed the quickest, simplest, best quality tenancy
                                            solution available in the UK.</p>
                                        <p>However, we realise that buying on the Internet can make some people feel
                                            a bit uneasy.</p>
                                        <p>That’s why we
                                            offer&nbsp;a&nbsp;no-quibble&nbsp;guarantee&nbsp;that&nbsp;completely
                                            eliminates any risk, fear or uncertainty you may feel.</p>
                                        <p>Therefore, if within 30 days of purchase you find another online tenancy
                                            agreement product that offers the same quality of service, documentation
                                            and support as ours, for less money, we will give you a <b>no-quibble
                                                100% refund GUARANTEED.</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sidebar" className="span4">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block about_text-block__1Un4d" id="thumbnail3">
                                    <img alt="300x200" src="../images/call-center.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                </div>
                            </div>
                            <div className="row-fluid">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
        );
    }

export default Guarantee;