import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function LodgerAgreement() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement’s Lodger Agreement</title>
                            <meta id="meta-description" name="description" content="A completed Lodger Agreement that clearly sets out your and your Lodger(s) responsibilities
                                prepared online, and then sent to you instantly"/>
                            <meta id="meta-keywords" name="keywords" content="Lodger Agreement, Spare Room Rental, Lodger Tenancy Agreement, Lodger Tenant Agreement,
                                    Room Agreement, Room Lease Agreement, Room Rental Agreement, Flat Share"/>
                            <meta id="og-title" property="og:title" content="Tenant Agreement’s Lodger Agreement"/>
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>Lodger Agreement
                                                <span className="pull-right"><Link to="/order-now/lodger_agreement.html"
                                                                                   className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>A Lodger Agreement is a written or verbal agreement between a
                                                resident landlord and the lodger(s) that also live in their property.
                                                Whilst there is no legal requirement for you to obtain such an
                                                agreement and renting a room in a property within which you reside is
                                                simpler than renting out an entire property, it is still advisable that
                                                you obtain a Lodger Agreement which specifies:</p>
                                            <ul>
                                                <li>The duration of the let (how long your lodger will be entitled to
                                                    reside in the relevant room);
                                                </li>
                                                <li>How much rent is payable and the frequency of these payments;</li>
                                                <li>Details of any deposits that are to be collected;</li>
                                                <li>An inventory of the items present within the relevant room and
                                                    their condition; and
                                                </li>
                                                <li>Any additional services that the landlord may provide to their
                                                    lodger(s) such as cleaning.
                                                </li>
                                            </ul>
                                            <p>Failing to obtain such an agreement can cause significant problems,
                                                and lead to you accruing unnecessary legal costs, in the event of a
                                                dispute. Fortunately, with a concise, clearly written and binding
                                                Lodger Agreement you can enjoy the peace of mind that comes from
                                                knowing that you’re covered in the event of a dispute between yourself
                                                and your lodger(s) - <b>and all for just £9.99!</b></p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200"
                                             src="../images/package3-img.jpg"/>
                                        <div className="caption">
                                            <h3>
                                                Information
                                            </h3>
                                            <p>A completed and checked Tenancy Agreement, and a variety of essential
                                                documents to help you understand and adhere to legal requirements for
                                                only &pound; 19.99. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on<br/><a id="contact-number"
                                                                                             href="tel:08004402873">0800
                                            4402 873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default LodgerAgreement;