import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function PrivacyPolicy() {

    return (
        <div className="container">
            <div className="row-fluid">
                <MetaTags>
                    <title>Tenant Agreement – Terms and conditions</title>
                    <meta id="meta-description" name="description"
                          content="Tenant Agreement’s Privacy Policy, Terms and conditions"/>
                    <meta id="meta-keywords" name="keywords"
                          content="Tenant Agreement’s Privacy Policy, Privacy Policy, Terms and conditions"/>
                    <meta id="og-title" property="og:title" content="Tenant Agreement – Privacy Policy"/>
                </MetaTags>
                <div className="span12 content-container">
                    <div className="row-fluid">
                        <div id="content" className="span8">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block">
                                    <div className="caption">
                                        <legend>Terms and conditions</legend>

                                        <p>This policy sets out the basis on which any personal data we collect from
                                            you, or that you provide to us, will be processed by us. Please read the
                                            following carefully to understand our views and practices regarding your
                                            personal data and how we will treat it. </p>
                                        <p>For the purpose of the Data Protection Act 1998, the data controller is
                                            Tenancy Agreement of Fields House, 12/13 Old Fields Road, Pencoed, Bridgend
                                            CF35 5LJ. </p>

                                        <ol>
                                            <li>INFORMATION WE MAY COLLECT FROM YOU</li>
                                            <p>We may collect and process the following data about you: </p>

                                            <ul>
                                                <li>Information that you provide by filling in forms on our site
                                                    www.tenant-agreement.co.uk. This includes information provided at
                                                    the time of registering to use our site, subscribing to our service,
                                                    posting material or requesting further services. We may also ask you
                                                    for information when you enter a competition or promotion sponsored
                                                    by Tenancy Agreement, and when you report a problem with our site.
                                                </li>
                                                <li>If you contact us, we may keep a record of that correspondence.</li>
                                                <li>Tenancy Agreement may also ask you to complete surveys that we use
                                                    for research purposes, although you do not have to respond to them.
                                                </li>
                                                <li>Details of transactions you carry out through our site and of the
                                                    fulfilment of your orders.
                                                </li>
                                                <li>Details of your visits to our site [including, but not limited to,
                                                    traffic data, location data, weblogs and other communication data,
                                                    whether this is required for our own billing purposes or otherwise]
                                                    and the resources that you access.
                                                </li>
                                            </ul>
                                            <br/>
                                            <li>IP ADDRESSES</li>
                                            <p>We may collect information about your computer, including where
                                                available your IP address, operating system and browser type, for
                                                system administration and to report aggregate information to our
                                                advertisers. This is statistical data about our users' browsing
                                                actions and patterns, and does not identify any individual. </p>

                                            <li>COOKIES</li>
                                            <p>We use a number of different cookies on our site. If you do not know
                                                what cookies are, or how to control or delete them, then we
                                                recommend you visit http://www.aboutcookies.org for detailed
                                                guidance. </p>
                                            <p>The list below describes the cookies we use on this site and what we
                                                use them for. Currently we operate an 'implied consent' policy which
                                                means that we assume you are happy with this usage. If you are not
                                                happy, then you should either not use this site, or you should
                                                delete the cookies having visited the site, or you should browse the
                                                site using your browser's anonymous usage setting (called
                                                "Incognito" in Chrome, "InPrivate" for Internet Explorer, "Private
                                                Browsing" in Firefox and Safari etc. </p>

                                            <i>First Party Cookies </i>
                                            <p>These are cookies that are set by this website directly: </p>
                                            <p>Google Analytics: We use Google Analytics to collect information
                                                about visitor behaviour on our website. Google Analytics stores
                                                information about what pages you visit, how long you are on the
                                                site, how you got here and what you click on. This Analytics data is
                                                collected via a JavaScript tag in the pages of our site and is not
                                                tied to personally identifiable information. We therefore do not
                                                collect or store your personal information (e.g. your name or
                                                address) so this information cannot be used to identify who you
                                                are. </p>
                                            <p>You can find out more about Google’s position on privacy as regards
                                                its analytics service at <a
                                                    href="http://www.google.com/intl/en_uk/analytics/privacyoverview.html">http://www.google.com/intl/en_uk/analytics/privacyoverview.html</a>
                                            </p>

                                            <i>Third Party Cookies </i>
                                            <p>These are cookies set on your machine by external websites whose
                                                services are used on this site. Cookies of this type are
                                                the&nbsp;sharing buttons across the site allow visitors to share
                                                content onto social networks. Cookies are currently set by <b>LinkedIn,
                                                    Twitter, Facebook and Google+</b>. In order to implement these
                                                buttons, and connect them to the relevant social networks and
                                                external sites, there are scripts from domains outside of our
                                                website. You should be aware that these sites are likely to be
                                                collecting information about what you are doing all around the
                                                internet, including on this website. </p>
                                            <br/>
                                            <li>WHERE WE STORE YOUR PERSONAL DATA</li>
                                            <p>The data that we collect from you may be transferred to, and
                                                stored at, a destination outside the European Economic Area
                                                ("EEA"). It may also be processed by staff operating outside the
                                                EEA who work for us or for one of our suppliers. Such staff
                                                maybe engaged in, among other things, the fulfilment of your
                                                order, the processing of your payment details and the provision
                                                of support services. By submitting your personal data, you agree
                                                to this transfer, storing or processing. Tenancy Agreement will
                                                take all steps reasonably necessary to ensure that your data is
                                                treated securely and in accordance with this privacy
                                                policy. </p>
                                            <p>All information you provide to Tenancy Agreement is stored on our
                                                secure servers. Any payment transactions will be encrypted
                                                [using SSL technology]. Where we have given you (or where you
                                                have chosen) a password which enables you to access certain
                                                parts of our site, you are responsible for keeping this password
                                                confidential. We ask you not to share your password with
                                                anyone. </p>
                                            <p>
                                                Unfortunately, the transmission of information via the internet
                                                is not completely secure. Although we will do our best to
                                                protect your personal data, we cannot guarantee the security of
                                                your data transmitted to our site; any transmission is at your
                                                own risk. Once we have received your information, we will use
                                                strict procedures and security features to try to prevent
                                                unauthorised access.
                                            </p>

                                            <br/>
                                            <li>HOW WE USE YOUR INFORMATION</li>
                                            <p>We use information held about you in the following ways: </p>
                                            <ul>
                                                <li>To ensure that content from our site is presented in the
                                                    most effective manner for you and for your computer.
                                                </li>
                                                <li>To provide you with information, products or services
                                                    that you request from us or which we feel may interest
                                                    you, where you have consented to be contacted for such
                                                    purposes.
                                                </li>
                                                <li>To carry out our obligations arising from any contracts
                                                    entered into between you and us.
                                                </li>
                                                <li>To allow you to participate in interactive features of
                                                    our service, when you choose to do so.
                                                </li>
                                                <li>To notify you about changes to our service.</li>
                                            </ul>

                                            <p>We may also use your data, or permit selected third parties
                                                to use your data, to provide you with information about
                                                goods and services which may be of interest to you and we or
                                                they may contact you about these by post, email or
                                                telephone. </p>
                                            <p>If you are an existing customer, we will only contact you by
                                                electronic means (e-mail or SMS) with information about
                                                goods and services similar to those which you have purchased
                                                from Tenancy Agreement previously. </p>
                                            <p>If you are a new customer, and where we permit selected third
                                                parties to use your data, we (or they) will contact you by
                                                electronic means only if you have consented to this. </p>
                                            <p>If you do not want us to use your data in this way, or to
                                                pass your details on to third parties for marketing
                                                purposes, please tick the relevant box situated on the form
                                                on which we collect your data (the registration form). </p>
                                            <p>[We do not disclose information about identifiable
                                                individuals to our advertisers, but we may provide them with
                                                aggregate information about our users (for example, we may
                                                inform them that 500 men aged under 30 have clicked on their
                                                advertisement on any given day). We may also use such
                                                aggregate information to help advertisers reach the kind of
                                                audience they want to target (for example, women in SW1). We
                                                may make use of the personal data we have collected from you
                                                to enable us to comply with our advertisers' wishes by
                                                displaying their advertisement to that target
                                                audience.] </p>
                                            <br/>
                                            <li>DISCLOSURE OF YOUR INFORMATION</li>
                                            <ul>
                                                <li>We may disclose your personal information to any
                                                    member of our group, which means our subsidiaries,
                                                    our ultimate holding company and its subsidiaries,
                                                    as defined in section 1159 of the UK Companies Act
                                                    2006.
                                                </li>
                                                <li>We may disclose your personal information to third
                                                    parties:
                                                </li>
                                                <li>In the event that we sell or buy any business or
                                                    assets, in which case we may disclose your personal
                                                    data to the prospective seller or buyer of such
                                                    business or assets.
                                                </li>
                                                <li>If Tenancy Agreement or substantially all of its
                                                    assets are acquired by a third party, in which case
                                                    personal data held by it about its customers will be
                                                    one of the transferred assets.
                                                </li>
                                                <li>If we are under a duty to disclose or share your
                                                    personal data in order to comply with any legal
                                                    obligation, or in order to enforce or apply our
                                                    terms of use or terms and conditions of supply and
                                                    other agreements; or to protect the rights,
                                                    property, or safety of Tenancy Agreement, our
                                                    customers, or others. This includes exchanging
                                                    information with other companies and organisations
                                                    for the purposes of fraud protection and credit risk
                                                    reduction.
                                                </li>
                                            </ul>
                                            <br/>
                                            <li>YOUR RIGHTS</li>
                                            <p>You have the right to ask us not to process your
                                                personal data for marketing purposes. We will
                                                usually inform you (before collecting your data) if
                                                we intend to use your data for such purposes or if
                                                we intend to disclose your information to any third
                                                party for such purposes. You can exercise your right
                                                to prevent such processing by checking certain boxes
                                                on the forms we use to collect your data. You can
                                                also exercise the right at any time by contacting us
                                                at <a
                                                    href="mailto:webmaster@tenant-agreement.co.uk">webmaster@tenant-agreement.co.uk</a>.
                                                Our site may, from time to time, contain links to
                                                and from the websites of our partner networks,
                                                advertisers and affiliates. If you follow a link to
                                                any of these websites, please note that these
                                                websites have their own privacy policies and that we
                                                do not accept any responsibility or liability for
                                                these policies. Please check these policies before
                                                you submit any personal data to these websites. </p>
                                            <br/>
                                            <li>ACCESS TO INFORMATION</li>
                                            <p>The Act gives you the right to access information
                                                held about you. Your right of access can be
                                                exercised in accordance with the Act. Any access
                                                request may be subject to a fee
                                                of &amp;pouind;10 to meet our costs in providing
                                                you with details of the information we hold
                                                about you.
                                            </p>
                                            <br/>
                                            <li>CHANGES TO OUR PRIVACY POLICY</li>
                                            <p>Any changes we may make to our privacy policy
                                                in the future will be posted on this page
                                                and, where appropriate, notified to you by
                                                e-mail. </p>

                                            <br/>
                                            <li>CONTACT</li>
                                            <p>Questions, comments and requests
                                                regarding this privacy policy are
                                                welcomed and should be addressed to <a
                                                    href="mailto:webmaster@tenant-agreement.co.uk">webmaster@tenant-agreement.co.uk</a>.
                                            </p>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sidebar" className="span4">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block about_text-block__1Un4d" id="thumbnail3">
                                    <img alt="300x200" src="../images/call-center.jpg"/>
                                    <div className="caption">
                                        <h3>
                                            Service
                                        </h3>
                                        <p>
                                            We are dedicated to ensuring that each of our customers enjoys a
                                            superior service. All of our processes and documents have been
                                            rigorously reviewed in order to ensure their accuracy and validity.

                                            We also provide all of our customers with free phone and email
                                            support for any queries that they may have concerning any of our
                                            products.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row-fluid">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default PrivacyPolicy;