import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import testimonial from "../../files/testimonial.json";
import solicitor from "../../images/solicitor.png";

function Testimonials() {

    const testimonialsList = testimonial.map((testimonial, index ) =>
        <div key={index} className={testimonial.id % 2 ? "media testimonial-odd" : "media testimonial-even"}  >
            <img src={solicitor}
                 className="media-object pull-left img-polaroid client-img" alt="avatar" />
                <div className="media-body">
                    <strong>{testimonial.name}</strong>, {testimonial.location}
                    <blockquote>{testimonial.testimony}</blockquote>
                </div>
        </div>
    );

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Testimonials</title>
                            <meta id="meta-description" name="description"
                                  content=" We think our Tenancy Agreements are the best in the
                                UK, but don’t just take our word for it – see what our customers have to
                                say "/>
                            <meta id="meta-keywords" name="keywords"
                                  content=" Tenant Agreement Testimonials, Tenant Agreement Reviews"/>
                            <meta id="og-title" property="og:title" content="Testimonials"/>
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>What our Clients Say</legend>
                                            {testimonialsList}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                </div>
                                <div className="row-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} mostOrderProduct={true} />
        </div>
        );
}

export default Testimonials;