import React from "react";
import MetaTags from 'react-meta-tags';
import "../../styles/home.module.css"
import {SecondBox} from "./../../components";
import {Link} from "react-router-dom";
import selecteServiceMenuItem from "../../scripts/scripts.js";


function Home(props) {

    return (
        <div>
            <section className="container">
                <MetaTags>
                    <title>Downloadable Tenancy Agreements</title>
                    <meta id="meta-description" name="description"
                          content="Tenancy Agreements that are both comprehensive, legally binding and can be downloaded, along with other vital documents for landlords, in minutes"/>
                    <meta id="meta-keywords" name="keywords"
                          content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement, download tenancy agreement, tenancy agreement template"/>
                    <meta id="og-title" property="og:title" content="Downloadable Tenancy Agreements"/>
                </MetaTags>
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span6 thumbnail text-block" id="thumbnail1">
                                        <img alt="300x200" src="./images/package1-img.jpg" />
                                        <div className="caption">
                                            <h3 className="order-title home_order-title__164Vv">
                                                Download Service
                                            </h3>
                                            <p className="order-description home_order-description__3-9be">
                                                A concise, clear and legally-binding Tenancy Agreement completed for
                                                you within a matter
                                                of minutes – available for just <b>£9.99!</b>
                                            </p>
                                            <p className="order-paragraph home_order-paragraph__CpN44">
                                                <Link onClick={selecteServiceMenuItem} className="order-button btn-large btn btn-danger" id="package-1"
                                                   to="/order-now/download_service.html">Learn More</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="span6 thumbnail text-block" id="thumbnail2">
                                        <img alt="300x200" src="../images/package2-img.jpg" />
                                        <div className="caption">
                                            <h3 className="order-title home_order-title__164Vv">
                                                Personal Service
                                            </h3>
                                            <p className="order-description">
                                                Your Tenancy Agreement completed for you, checked for discrepancies
                                                by an experienced case manager,
                                                and then returned to you within 24 hours – and all for
                                                just <b>£14.99!</b>
                                            </p>
                                            <p className="order-paragraph home_order-paragraph__CpN44">
                                                <Link onClick={selecteServiceMenuItem} className="btn btn-large btn-danger " id="package-2"
                                                   to="/order-now/checked_service.html">Learn More</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="./images/package3-img.jpg" />
                                        <div className="caption">
                                            <h3 className="order-title home_order-title__164Vv">
                                                Landlord Pack
                                            </h3>
                                            <p className="order-description">
                                                A completed and checked Tenancy Agreement, and a variety of
                                                essential documents to help
                                                you understand and adhere to legal requirements for
                                                only <b>£19.99!</b>
                                            </p>
                                            <p className="order-paragraph home_order-paragraph__CpN44">
                                                <Link onClick={selecteServiceMenuItem} className="btn btn-large btn-danger" id="package-3"
                                                   to="/order-now/landlord_pack.html">Learn More</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox list={true} menuItemSelectionOnClick={props.selecteServiceMenuItem} />
        </div>
    );
}

export default Home;