import React from "react";
import MetaTags from "react-meta-tags";
import "../../styles/about.module.css"
import {SecondBox} from "../../components";
import {Link} from "react-router-dom";

function About() {
    return (
        <div className="container">
            <div className="row-fluid">
                <div className="span12 content-container">
                    <MetaTags>
                        <title>About Tenancy Agreements</title>
                        <meta id="meta-description" name="description"
                              content="A Tenancy Agreement is an essential document for anyone that rents out their property
                        to tenants, but why? This page describes what a Tenancy Agreement does
                        "/>
                        <meta id="meta-keywords" name="keywords"
                              content="Tenancy Agreement, Assured Shorthold Tenancy Agreement, What is a Tenancy Agreement,
                        What is an Assured Shorthold Tenancy Agreement, Do I Need a Tenancy Agreement,
                        What Does a Tenancy Agreement Cover
                        "/>
                        <meta id="og-title" property="og:title" content="About Tenancy Agreements"/>
                    </MetaTags>
                    <div className="row-fluid">
                        <div id="content" className="span8">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block">
                                    <div className="caption">
                                        <legend>
                                            About Tenancy Agreement
                                            <span className="pull-right"><Link to="/services.html"
                                                                               className="btn btn-primary">Order Now!</Link></span>
                                        </legend>

                                        <p>A Tenancy Agreement is a written or verbal contract between a landlord and
                                            their
                                            tenant(s) that specifies
                                            the rights of both parties with regards to the property in question, such
                                            as:</p>

                                        <ul>
                                            <li>The duration of the tenancy;</li>
                                            <li>The amount of notice that either party must provide to the other before
                                                terminating the tenancy;
                                            </li>
                                            <li>How much rent is payable, the frequency of these payments and whether
                                                these
                                                payments include
                                                bills for fuel, water etc.;
                                            </li>
                                            <li>The responsibilities of both the landlord and tenant(s) with regards to
                                                maintaining the property;
                                            </li>
                                            <li>Any additional services that the landlord may provide to their
                                                tenant(s).
                                            </li>
                                        </ul>

                                        <p>Whilst there is no legal requirement for landlords to provide their tenants
                                            with
                                            a written agreement – and you may,
                                            instead, rely on verbal agreements reached between you and your tenant(s) –
                                            failing to do so can cause significant
                                            problems, and lead to you accruing unnecessary legal costs, in the event of
                                            a
                                            dispute.</p>
                                        <p>With a concise, clearly written and binding Tenancy Agreement you can enjoy
                                            the
                                            peace of mind that comes from
                                            knowing that you’re covered in the event of a dispute between yourself and
                                            your
                                            tenant(s).</p>
                                        <p>Tenancy Agreement offers a variety of packages to landlords and can provide
                                            you
                                            with a binding agreement for
                                            <b>just £9.99</b>. <Link to="/services.html">Click here</Link> to find out
                                            more
                                            about the packages we offer.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sidebar" className="span4">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block" id="thumbnail3">
                                    <img alt="300x200" src="./images/call-center.jpg"/>
                                    <div className="caption">
                                        <h3>
                                            Service
                                        </h3>
                                        <p>
                                            We are dedicated to ensuring that each of our customers enjoys a superior
                                            service. All of our processes and documents have been rigorously reviewed in
                                            order to ensure their accuracy and validity.

                                            We also provide all of our customers with free phone and email support for
                                            any queries that they may have concerning any of our products.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row-fluid">

                            </div>
                        </div>
                    </div>
                </div>
                <SecondBox whyChooseUs={true} testimonials={true} list={false}/>
            </div>
        </div>
    );
}

export default About;