import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function LandlordInventory() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement's Landlord Inventory Form</title>
                            <meta id="meta-description" name="description" content="An Inventory Form is used to outline all of the items contained within a property and ensure
                                that your tenants pay for any damage they may cause." />
                            <meta id="meta-keywords" name="keywords" content="Landlord Inventory Form, Download a Landlord Inventory Form, Documents for Landlords, Download
                                Documents for Landlords, Landlord Services, Protect your Rented Property, Protected Your
                                Rented Property from Damage, Tenant Damaged Property, My Tenant has Damaged my Property" />
                            <meta id="og-title" property="og:title" content="Tenant Agreement's Landlord Inventory Form" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>Landlord Inventory Form
                                                <span className="pull-right"><Link to="/order-now/inventory.html"
                                                                                className="btn btn-primary">Order Now!</Link></span>
                                            </legend>
                                            <p>Also known as a Schedule of Condition, an Inventory Form is used to
                                                outline all of the items contained within a property as well as their,
                                                and the property’s condition, prior to a tenancy commencing.</p>
                                            <p>
                                                Without an Inventory Form that has been signed by your tenant, it can
                                                be extremely difficult to <b>prove that your tenant has damaged your
                                                property</b> or any items contained within it, thus making it
                                                difficult for you to <b>charge them for any repairs or replacements</b>.
                                            </p>
                                            <p>
                                                Tenancy Agreement can provide you with a template Inventory Form so you
                                                can rest assured that the condition of both your property and the items
                                                contained within are well documented prior to a tenancy commencing and
                                                that you’ll never be left out of pocket as a result of damage caused by
                                                your tenants as a result – <b>all just £4.99!</b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="../images/call-center.jpg" />
                                            <div className="caption">
                                                <h3>
                                                    Want to Know More?
                                                </h3>
                                                <p>Want to know more about what a Tenancy Agreement is, what it does and why
                                                    having one could save you time and money? </p>
                                                <p className="text-center"><Link to="/about.html"
                                                                              className="btn btn-primary">Find Out More!</Link>
                                                </p>
                                            </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on<br /><a id="contact-number"
                                                                                            href="tel:08004402873">0800 4402
                                            873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default LandlordInventory;