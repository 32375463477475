import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";

function ButtonSwap(props) {

    const MM_swapImage = (imageName, imagePath) => {
        $("[name='" + imageName + "']").attr("src", imagePath);
    }

    const MM_restoreImage = (imageName, imagePath) => {
        $("[name='" + imageName + "']").attr("src", imagePath);
    }

    return (
        <Link to={props.to} onMouseOut={() => MM_restoreImage(props.imageName, props.imagePath)}
              onMouseOver={() => MM_swapImage(props.imageName, props.imagePathRestore)}>
            <img src={props.imagePath} alt={props.alt} name={props.imageName} border="0"/>
        </Link>
    );

}

export default ButtonSwap;