import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function TenantGuarantor() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement’s Tenant Guarantor Form</title>
                            <meta id="meta-description" name="description" content="A Tenant Guarantor Form provides you extra security in the event of your tenant falling
                            into arrears
                            " />
                            <meta id="meta-keywords" name="keywords" content="Keywords: Tenant Guarantor Form, Guarantor Form, Make Sure Tenants Pay Rent, How to Make
                            Sure your Tenants Pay Rent on time, Rent Guarantor, Tenant in Arrears, My Tenant is in
                            Arrears, Documents for Landlords, Download a Tenant Guarantor Form, Guarantee Rent
                            " />
                            <meta id="og-title" property="og:title" content="Tenant Agreement’s Tenant Guarantor Form" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>Tenant Guarantor Form
                                                <span className="pull-right">
                                                    <Link to="/order-now/tenant_guarantor.html" className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>
                                                Whilst you may be able to recover any losses that you suffer as a
                                                result of your tenant damaging your property or failing to provide you
                                                with rent, claiming what you are owed from these individuals can prove
                                                difficult and it is advisable that you obtain the <b>additional
                                                safety</b>
                                                afforded to you through a Guarantor Form as a result.
                                            </p>

                                            <p>
                                                With a Guarantor Form you have the <b>added security</b> of knowing
                                                that it is not only your tenant but also another individual – such as
                                                your tenant’s friend or relative – that is <b>liable for any loss that
                                                you may suffer</b>, thus enabling you to claim any losses that you
                                                suffer as a result of your tenant’s action from this individual
                                                providing you with <b>greater confidence and peace of mind</b> – <b>and
                                                all for just £4.99!</b>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200"
                                             src="../images/package3-img.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Want to Know More?
                                            </h3>
                                            <p>As well as a Tenant Deposit Scheme Form, our Landlord
                                                Pack includes other essential documents such as a
                                                Guarantor Form, Eviction Notices and more. </p>
                                            <p className="text-center"><Link to="/landlord-pack.html"
                                                                          className="btn btn-primary">Find
                                                Out More!</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on<br /><a id="contact-number"
                                                                                            href="tel:08004402873">0800
                                            4402 873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default TenantGuarantor;