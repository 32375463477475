import $ from 'jquery';

$(function(){
	$('.packages-grid li').hover(
		function(){

			var l = $(this).attr('class');

			$(this).addClass('active');
			
			$('.active .img-overlay').animate ({
				'opacity' : '0.3'
			});
			
			if (l === 'top') {
				$('.active img').animate({
					'margin-top' : '55px'
				},300)
			} else if (l === 'mid'){
				$('.active img').animate({
					'margin-top' : '68px',
					'margin-left' : '58px'
				},300)
			} else if (l === 'bottom'){
				$('.active img').animate({
					'margin-top' : '52px',
					'margin-left' : '48px'
				},300)
			}
		},
		function() {
			var l = $(this).attr('class');
			$('.active .img-overlay').clearQueue();
            $('.active img').clearQueue();
			$('.active .img-overlay').animate ({
				'opacity' : '1'
			});

			if (l === 'top active'){
				$('.active img').animate({
					'margin-top' : '34px'
				},300)
			} else if (l === 'mid active'){
				$('.active img').animate({
					'margin-top' : '46px',
					'margin-left' : '84px'
				},300)
			} else if (l === 'bottom active'){
				$('.active img').animate({
					'margin-top' : '45px',
					'margin-left' : '24px'
				},300)
			}

			$(this).removeClass('active');
		}
	);
});