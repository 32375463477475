import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function LandLordPack() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement’s Comprehensive Landlord Pack</title>
                            <meta id="meta-description" name="description" content="A completed Tenancy Agreement, prepared and sent to you within 24 hours, and other
                                essential documents for landlords
                                " />
                            <meta id="meta-keywords" name="keywords" content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                Checked Tenancy Agreement, Checked assured shorthold agreement, Checked assured shorthold
                                tenancy agreement, Tenant Deposit Scheme, Eviction Notice Form, Eviction Notice Form,
                                Landlord Pack, Tenant Guarantor Form, Documents for Landlords
                                " />
                            <meta id="og-title" property="og:title" content="Tenant Agreement’s Comprehensive Landlord Pack" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>
                                                Landlord Pack
                                                <span className="pull-right"><Link to="/order-now/landlord_pack.html"
                                                                                   className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <p>A completed Tenancy Agreement that clearly sets out your and your
                                                tenant(s) responsibilities, checked for discrepancies, drafted for you
                                                and sent to you within 24 hours and all of the documentation that you
                                                will need to ensure that you adhere to the legal requirements placed
                                                upon you as a landlord.</p>

                                            <p>Our Landlord Pack includes:</p>
                                            <ul>
                                                <li>A Tenant Guarantor Form – to protect you from lost rent, damage to
                                                    your property and any legal fees accrued by your tenant
                                                </li>
                                                <li>A Tenant Deposit Scheme Notice Form – to ensure that you comply with
                                                    the Tenancy
                                                    Deposit Scheme (TDS) legislation
                                                </li>
                                                <li>An S8 Eviction Form – to allow you to legally evict a tenant if they
                                                    breach a term of your agreement such as the payment of rent
                                                </li>
                                                <li>An S21 Eviction Form – to allow you to legally repossess a property
                                                    following the fixed tenancy term having expired
                                                </li>
                                                <li>An Inventory Form</li>
                                                <p>Our Landlord Pack includes everything that you will need to rent
                                                    your property safe in the knowledge that you have adhered to all
                                                    legislation for <b>just £19.99!</b></p>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="../images/package3-img.jpg"/>
                                        <div className="caption">
                                            <h3>
                                                Information
                                            </h3>
                                            <p>A completed and checked Tenancy Agreement, and a variety of essential
                                                documents to help you understand and adhere to legal requirements
                                                for only £19.99.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on <br/>
                                            <a id="contact-number"
                                               href="tel:08004402873">0800
                                                4402 873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default LandLordPack;