import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import"../../styles/will.module.css"
import {Link} from "react-router-dom";


function Step1() {
    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>How to Download a Tenancy Agreement – Step 1</title>
                            <meta id="meta-description" name="description" content="Your Tenancy Agreement can be prepared for you within a matter of minutes with Tenant Agreement - find out how here" />
                            <meta id="meta-keywords" name="keywords" content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                download tenancy agreement, download a tenancy agreement, how to download a Tenancy Agreement" />
                            <meta id="og-title" property="og:title" content="How to Download a Tenancy Agreement – Step 1" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V">
                                        <div className="caption">
                                            <legend>Step 1 <span className="pull-right"><Link
                                                className="btn btn-small btn-primary" to="/step2.html">Next <i
                                                className="icon-chevron-right"></i></Link></span></legend>
                                            <p>To automatically produce a completed Tenancy Agreement you only need to
                                                fill in a simple online questionnaire.</p>

                                            <p>You can complete the questionnaire in minutes or simply click the ‘save’
                                                button and return to complete it at your own pace and in your own
                                                time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V" id="thumbnail1">
                                        <img alt="300x200" src="../images/group-lawyer.jpg" />
                                            <div className="caption will_caption__2CudZ">
                                                <h3>
                                                    Expertise
                                                </h3>
                                                <p>
                                                    Tenancy Agreement was established by a group of legal experts
                                                    committed to making the process of obtaining a legally-binding
                                                    Tenancy Agreements as quick, simple and affordable as possible.

                                                    Each of our clients can rest assured that all the documents that we
                                                    prepare are totally accurate and legally correct.
                                                </p>
                                            </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default Step1;