import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function Disclaimer() {

    return (
        <div className="container">
            <div className="row-fluid">
                <MetaTags>
                    <title>Disclaimer</title>
                    <meta id="meta-description" name="description"
                          content="Tenancy Agreements that are both comprehensive, legally binding and can be downloaded, along with other vital documents for landlords, in minutes"/>
                    <meta id="meta-keywords" name="keywords"
                          content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement, download tenancy agreement, tenancy agreement template"/>
                    <meta id="og-title" property="og:title" content="Disclaimer"/>
                </MetaTags>
                <div className="span12 content-container">
                    <div className="row-fluid">
                        <div id="content" className="span8">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block">
                                    <div className="caption">
                                        <legend>Disclaimer</legend>

                                        <p>Tenant Agreement does not provide any legal advice and users of
                                            this web site should consult with their own solicitor for legal
                                            advice should this be required.</p>


                                        <p>While we use reasonable efforts to include accurate and up-to-date
                                            information on the Site, we do not represent, warrant or promise
                                            (whether express or implied) that any information is or remains
                                            accurate, complete and up to date, or fit or suitable for any
                                            purpose. Any reliance you place on the information on the Site is at
                                            your own risk. Nothing in this disclaimer shall operate to prejudice
                                            any mandatory statutory requirement or your statutory rights.</p>

                                        <p>Content on the Site is provided for your general information
                                            purposes only and to inform you about us and our products and news,
                                            features, services and other websites which may be of interest. It
                                            does not constitute technical, financial, legal advice or any other
                                            type of advice. You agree that your use of the Site is on an 'as is'
                                            and 'as available' basis. Consequently we make no representations,
                                            warranties, conditions or other terms (whether express or implied) in
                                            relation to the provision of the Site, including without limitation
                                            as to completeness, accuracy and currency or any content and
                                            information on the Site, or as to satisfactory quality, or fitness
                                            for particular purpose.</p>

                                        <p>To the maximum extent permitted by applicable law, we exclude all
                                            liability (whether arising in contract, tort, breach of statutory
                                            duty or otherwise) which we may otherwise have to you as a result of:</p>

                                        <ul>
                                            <li>any error or inaccuracies in any information or material within
                                                or relating to the Site;
                                            </li>
                                            <li>the unavailability of the Site for whatsoever reason; and</li>
                                            <li>any representation or statement made on the Site.</li>
                                            <li>Under no circumstances shall we be liable to you for any loss or
                                                damage suffered (including without limitation direct or indirect
                                                losses) arising from your use of, or reliance on, the Site.
                                            </li>
                                        </ul>

                                        <p>If you are in breach of any of our terms and conditions, you agree
                                            to indemnify and hold us harmless in respect of any costs, expenses,
                                            claims, proceedings, actions, losses, damages or liabilities incurred
                                            by us in relation to or arising from such a breach.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sidebar" className="span4">
                            <div className="row-fluid">
                                <div className="span12 thumbnail text-block" id="thumbnail3">
                                    <img alt="300x200" src="../images/call-center.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                </div>
                            </div>
                            <div className="row-fluid">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
        );
    }

export default Disclaimer;