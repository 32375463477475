import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import "../../styles/will.module.css"
import {Link} from "react-router-dom";


function Step2() {
    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>How to Download a Tenancy Agreement – Step 2</title>
                            <meta id="meta-description" name="description"
                                  content="Your Tenancy Agreement can be prepared for you within a matter of minutes with Tenant Agreement - find out how here"/>
                            <meta id="meta-keywords" name="keywords"
                                  content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                            download tenancy agreement, download a tenancy agreement, how to download a Tenancy Agreement
                            "/>
                            <meta id="og-title" property="og:title" content="How to Download a Tenancy Agreement – Step 2"/>
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V">
                                        <div className="caption">
                                            <legend>Step 2
                                                <span className="pull-right">
                                                    <Link className="btn btn-small" to="/step1.html">
                                                        <i className="icon-chevron-left"></i> Previous </Link> <Link className="btn btn-small btn-primary" to="/step3.html">Next
                                                        <i className="icon-chevron-right"></i>
                                                    </Link>
                                                </span>
                                            </legend>
                                            <p>Once you have completed your questionnaire, submit your details with a
                                                single click. After completing the questionnaire your details are
                                                ‘submitted’ to produce your Tenancy Agreement.</p>

                                            <p>Following you having submitted our online questionnaire, your
                                                documentation can be downloaded directly to your desktop or emailed to
                                                you instantly, or within 24 hours if your package includes a checked
                                                agreement..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V" id="thumbnail2">
                                        <img alt="300x200" src="../images/terrace-houses.jpg"/>
                                        <div className="caption will_caption__2CudZ">
                                            <h3>
                                                Experience
                                            </h3>
                                            <p>
                                                Tenancy Agreement have been offering low-cost, binding tenancy
                                                agreements since 2005 and are firmly established as a reputable and
                                                respected provider of legal documentation. Having prepared thousands
                                                of documents, you can rest-assured that we possess the knowledge
                                                required to prepare comprehensive and binding agreements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default Step2;