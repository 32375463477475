import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function Faq() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenancy Agreements – Frequently Asked Questions</title>
                            <meta id="meta-description" name="description"
                                  content="Frequently Asked Questions about Tenancy Agreements and Tenant Agreement"/>
                            <meta id="meta-keywords" name="keywords"
                                  content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                What is a Tenancy Agreement, What is an Assured Shorthold Tenancy Agreement, Do I need a
                                Tenancy Agreement, What Terms can be Included in a Tenancy Agreement
                                "/>
                            <meta id="og-title" property="og:title" content="Tenancy Agreements – Frequently Asked Questions"/>
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <b>What is Tenancy Agreement?</b>
                                            <p>A Tenancy Agreement is a legally binding and concise contract between a
                                                tenant and a landlord with regards to a residential property.</p>

                                            <b>Can landlords write any terms and conditions they want into a
                                                contract? </b>
                                            <p>No, they can’t. The Unfair Terms in Consumer Contracts Regulations (1999)
                                                applies to all rented tenancies. As such any clauses deemed unfair could
                                                be unenforceable. This only refers to the standard terms of a contract
                                                (not clauses that have been separately negotiated). Examples of Unfair
                                                Terms could be penalty charges, exclusion by the landlord/agent of
                                                accepting responsibility for loss or damage to personal property and
                                                ambiguous legal clauses.</p>

                                            <b>What should the Tenancy Agreement Cover? </b>
                                            <ol>
                                                <li>Landlords Details</li>
                                                <li>Tenants Details</li>
                                                <li>Property Details</li>
                                                <li>Room Details</li>
                                                <li>Term of Tenure</li>
                                                <li>The Rent</li>
                                                <li>Method of Payment</li>
                                                <li>The Deposit</li>
                                                <li>The Inventory</li>
                                                <li>Details of the Agent</li>
                                            </ol>

                                            <b>Why are you so cheap? </b>
                                            <p>We are an expert online Legal service - therefore we have considerably
                                                fewer overheads than a high street solicitor - this keeps our costs down
                                                to a minimum, allowing us to pass on our savings to our customers.</p>

                                            <b>What should I do if I do not need to use all the terms of the
                                                contract? </b>
                                            <p>For any part(s) of the contract not required you must strike a line
                                                through the section and have both parties sign and initial it. </p>

                                            <b>Can I use an assured shorthold tenancy? </b>
                                            <p>Not everybody will be eligible to use an assured shorthold tenancy. The
                                                Housing Act defines certain tenancy categories that are excluded: </p>
                                            <ul>
                                                <li>Lettings to companies</li>
                                                <li>Lettings at no rent, low rent or high rent (over £25,000 per
                                                    annum)
                                                </li>
                                                <li>Holiday lettings</li>
                                                <li>Tenancies granted by a resident landlord i.e. a landlord who lives
                                                    in the same property as the tenant
                                                </li>
                                                <li>Tenancies of property let with more than two acres of agricultural
                                                    land, or an agricultural tenancy
                                                </li>
                                                <li>Tenancies entered into before 15 January 1989, or a tenancy that was
                                                    previously a protected tenancy
                                                </li>
                                            </ul>

                                            <p>The tenancies listed above are known as 'common law' or 'contractual'
                                                tenancies and not assured shorthold tenancies.</p>

                                            <p>If you are not excluded from the list above your tenancy must also meet
                                                three other requirements before you can get an assured shorthold tenancy
                                                agreement:</p>

                                            <ul>
                                                <li>the tenant is an individual</li>
                                                <li>the dwelling is let as separate accommodation</li>
                                                <li>the dwelling is the tenant's main or principal home</li>
                                            </ul>

                                            <b>What is Stamp Duty? </b>
                                            <p>Stamp duty is payable on all leases although in fact, stamping is not
                                                compulsory for short residential leases but a letting agreement must be
                                                stamped before it can be accepted in court as a valid document.
                                                Therefore, many firms and landlords do not bother to stamp tenancy
                                                agreements unless there is a risk that the document may be produced in
                                                court (i.e. if the landlord starts possesion proceedings). Although
                                                leases should be stamped within 30 days of execution, The Stamp Office
                                                will generally accept late documents on payment of a small penalty
                                                charge. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V" id="thumbnail1">
                                        <img alt="300x200" src="../images/call-center.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and
                                                validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default Faq;