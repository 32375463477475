import React, {Component} from "react";
import $ from 'jquery';
import "bootstrap";
import MetaTags from "react-meta-tags";
import SecondBox from "../../components/SecondBox";
import listServices from "../../files/listServices.json";
import ServiceImage from "../../images/call-center.jpg";
import {Link} from "react-router-dom";


// show this picture on the side meny when landing in the page.
$(document).ready(function () {
    $(".box-img-menu img").attr("src", "../images/picture9.png");
});

class OrderNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sErrorMsg: "",
            sErrorMsgTitle: "Check the following:",
            objFrmMain: "",
            arrValidDates: ['YY/DD/MM', 'YYMMDD', 'YYYYMMDD', 'YYYY-MM-DD'],
            iFormatDate: 0,
            sErrIsEmpty: " is required.\n",
            sErrFormat: "Err!",
            sErrNotChoosen: " is not choosen.\n",
            sErrValidateTextMinLength1: " must be at least ",
            sErrValidateTextMinLength2: " characters.\n",
            sErrValidateTextMaxLength1: " can be at most ",
            sErrValidateTextMaxLength2: " characters.\n",
            sErrValidateEmail: " is not a valid e-mail address.\n",
            sErrCompareDatesIsNot: " is not ",
            sErrCompareDatesSameAs: "same as",
            sErrCompareDatesDifferent: "different",
            displayNone: "none",
            monthNames: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            amount: '',
            product_ki: '',
            product_title: '',
            fullDescription: '',
            fullTitle: '',
            title: '',
            package_ki: '',
            description: '',
            selectedOptionValue: 'GB',
            selectedOptionText: 'United Kingdom ‪(+44)‬',
            selectedOptionIndex: 218
        };
    }


    // When the user starts to type something inside the password field
    validatePassword(sText, sName) {
        var errorMsg = "";

        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if (!sText.match(lowerCaseLetters)) {
            errorMsg += "- Password must contain at least a lower case letter<br/>";
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if (!sText.match(upperCaseLetters)) {
            errorMsg += "- Password must contain at least a capital letter<br/>";
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (!sText.match(numbers)) {
            errorMsg += "- Password must contain at least a number<br/>";
        }

        // Validate length
        if (sText.length < 8) {
            errorMsg += "- Password must contain at least 8 characters<br/>";
        }
        return errorMsg;
    }

    validateText(sText, sName, iMinLength, iMaxLength) {
        sText = $.trim(sText);
        var errorMsg = "";

        if (sText === "") // If empty
        {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        } else if (sText !== "") // else if to short or to long string
        {
            if (iMinLength !== null && sText.length < iMinLength) {
                errorMsg = "- " + sName + this.state.sErrValidateTextMinLength1 + iMinLength + this.state.sErrValidateTextMinLength2;
            }
            if (iMaxLength !== null && sText.length > iMaxLength)
                errorMsg = "- " + sName + this.state.sErrValidateTextMaxLength1 + iMaxLength + this.state.sErrValidateTextMaxLength2;
        }

        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }


    // 'clock' on order now page
    componentDidMount() {

        var urlParm = this.props.match.params;
        let listServicDetail = listServices.filter(function (item) {
            if (item.url === urlParm.package_id)
                return true;
            return false;
        }).map(function (item) {
            return item;
        });
        this.setState({
            fullDescription: listServicDetail[0].fullDescription,
            title: listServicDetail[0].title,
            description: listServicDetail[0].description,
            package_ki: listServicDetail[0].package_ki,
            fullTitle: listServicDetail[0].fullTitle,
            amount: listServicDetail[0].amount
        });
    }

    validateEmail(sEmail, sName) {
        /* emailChecker = verify a valid email */
        let emailChecker = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
        let trimmedEmail = $.trim(sEmail);
        var errorMsg = "";

        if (sEmail === "") {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        } else if (sEmail !== "") {
            if ((!emailChecker.test(trimmedEmail))) {
                errorMsg = "- " + sName + this.state.sErrValidateEmail;
            }
        }
        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }

    // validate the entire form
    validate(e) {
        e.preventDefault();
        let parentThis = this;
        let sErrorMsgFinale = "";
        $(".modal").remove();
        this.setState({sErrorMsgTitle: "Check the following:"});
        let form = document.frmMember;
        sErrorMsgFinale += this.validateText(form.firstName.value, "First name", 2, 20);
        sErrorMsgFinale += this.validateText(form.lastName.value, "Last name", 2, 20);
        sErrorMsgFinale += this.validateEmail(form.email.value, "Email");
        sErrorMsgFinale += this.validateText(form.address_1.value, "Address", false);
        sErrorMsgFinale += this.validateText(form.postcode.value, "Postcode", false, 10);
        sErrorMsgFinale += this.validatePassword(form.pass.value, "Password", false);
        sErrorMsgFinale += this.validateText(form.pass2.value, "Confirm Password", false);
        sErrorMsgFinale += this.validateText(form.city.value, "City", false, 20);
        sErrorMsgFinale += this.validateText(form.phone.value, "Telephone", false, 14);

        if (form.pass.value !== form.pass2.value) {
            sErrorMsgFinale += '- Passwords do not match';
        }

        let stripBrFromMessage = sErrorMsgFinale.replace(/(<([^>]+)>)/ig, '');

        // check if there are any error returned after stripping <br/> tag
        if ($.trim(stripBrFromMessage) !== "") {
            // If any error it will be displayed in the modal
            this.modalReport(sErrorMsgFinale);
            return false;
        } else {

            //get IP address
            var ip_address = null;
            $.ajax({
                url: "https://api.ipify.org?format=json",
                dataType: 'json',
                async: false,
                success:  function(data) {
                    ip_address = data.ip;
                }
            });

            //get site_client_id and create new client on QDS
            let endopointQDS = "https://qds.quickie-divorce.com/api/client-order";
            let workloadQDS = {
                'domain': 'tenant-agreement.co.uk',
                'first_name': form.firstName.value,
                'last_name': form.lastName.value,
                'password': form.pass.value,
                'email': form.email.value,
                'phone': form.phone.value,
                'address_1': form.address_1.value,
                'address_2': form.address_2.value,
                'postcode': form.postcode.value,
                'notes': form.product_title.value,
                'city': form.city.value,
                'package_ki': form.product_ki.value,
                'ip_address': ip_address
            }

            // prepare error messages text in case some are triggered
            this.setState({sErrorMsgTitle: "Something went wrong!"});
            this.setState({sErrorMsg: "Message could not be sent for unknown reasons! Please, try later and if the problem  persists contact the customer service."});

            // disable buttons and show invite to "wait" messages
            $("#singlebutton").attr("disabled", "disabled");
            $(".butt_back").attr("disabled", "disabled");
            $("#payment-gateway-alert, .spinner-border").slideDown();

            // API to QDS to create an order and retrieve client_id and order_id
            // to be provided to the next API to the payment gateway
            $.ajax({
                url: endopointQDS,
                method: "POST",
                data: workloadQDS
            }).fail(function () {
                parentThis.modalReport();
                parentThis.restoreButtonsAndAlerts();
            }).done(function (result) {
                if (typeof result.data.client_id !== "undefined" && typeof result.data.order_id !== "undefined") {
                    parentThis.sendPaymentRequest(parentThis, result, form);
                } else {
                    parentThis.modalReport();
                    parentThis.restoreButtonsAndAlerts();
                }
            });
        }
    }

    // send data to create a payment request and be redirected to the payment gateway
    sendPaymentRequest(parentThis, result, form) {
        // prepare data to be sent through API to the payment request UKDSsecure
        let endpointPG = 'https://securepay.ukds.net/api/request-payment';
        let ukdsUrlTokenPG = "https://securepay.ukds.net/?token=";
        let pre_vat = form.amount.value / 1.2;
        let var_amount = form.amount.value - pre_vat;
        let workloadPG = {
            company_id: 1,
            site_id: 2,
            site_client_id: result.data.client_id,
            site_job_number: result.data.order_id,
            first_name: form.firstName.value,
            last_name: form.lastName.value,
            email: form.email.value,
            tel: form.phone.value,
            address_1: form.address_1.value,
            address_2: form.address_2.value,
            city: form.city.value,
            postcode: form.postcode.value,
            country: 'GB',
            source: 'tenant-agreement.co.uk',
            currency: 'GBP',
            amount: form.amount.value,
            pre_vat: pre_vat.toFixed(2),
            vat_amount: var_amount.toFixed(2),
            package: form.product_title.value,
            processed_by: 'WEB',
            assigned_to: 'WEB',
            company_short_name: 'QD',
            securepay_enabled: 1
        }

        let paymentReqeustValidationResponseErrorList = "";

        // API to Payment Gateway
        $.ajax({
            url: endpointPG,
            method: "POST",
            data: workloadPG,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).fail(function (error) {
            if (typeof error.responseJSON !== "undefined" && typeof error.responseJSON.message !== "undefined" && typeof error.responseJSON.data !== "undefined") {
                for (var key in error.responseJSON.data) {
                    let errorString = error.responseJSON.data[key].toString();
                    paymentReqeustValidationResponseErrorList += " - " + errorString.replace(",", " ") + "<br/>";
                }
            }
            parentThis.modalReport(paymentReqeustValidationResponseErrorList);
        }).done(function (result) {
            if (result.message === "Success") {
                window.location.href = ukdsUrlTokenPG + result.data.token;
            } else {
                for (var key in result) {
                    paymentReqeustValidationResponseErrorList += result[key] + "<br/>";
                }
                parentThis.modalReport(paymentReqeustValidationResponseErrorList);
            }
        }).always(function () {
            parentThis.restoreButtonsAndAlerts();
        });
    }

    restoreButtonsAndAlerts() {
        $("#singlebutton").removeAttr("disabled");
        $("#payment-gateway-alert, .spinner-border").slideUp();
    }

    submitForm() {
        $("[name='frmMember']").submit();
    }

    modalReport(sErrorMessage) {
        let messageReport = '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
            '<div class="modal-dialog" role="document">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<h5 class="modal-title" id="exampleModalLabel">' + this.state.sErrorMsgTitle + '</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' +
            '</div>' +
            '<div class="modal-body">' + (sErrorMessage || this.state.sErrorMsg) + '</div>' +
            '<div class="modal-footer">' +
            '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        $("body", window.parent.document).append(messageReport);
        window.$('#exampleModal').modal('show');
    }

    render() {
        return (
            <div>
                <section className="content_wrapper">
                    <div className="row-fluid">
                        <div className="span12 content-container">
                            <MetaTags>
                                <title>Order Now</title>
                                <meta id="meta-description" name="description"
                                      content="Tenancy Agreements that are both comprehensive, legally binding and can be
                                      downloaded, along with other vital documents for landlords, in minutes"/>
                                <meta id="meta-keywords" name="keywords"
                                      content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy
                                      agreement, download tenancy agreement, tenancy agreement template"/>
                                <meta id="og-title" property="og:title" content="Order Now"/>
                            </MetaTags>
                            <div className="row-fluid">
                                <div id="content" className="span8">
                                    <div className="row-fluid">
                                        <div className="span12 thumbnail text-block">
                                            <div className="inner-content">
                                                <form className="form-horizontal" name="frmMember"
                                                      action="/order-submit.html"
                                                      method="post">

                                                    <input type="hidden" name="product_title"
                                                           value={this.state.title}/>
                                                    <input type="hidden" name="product_ki"
                                                           value={this.state.package_ki}/>
                                                    <input type="hidden" name="product_description"
                                                           value={this.state.description}/>
                                                    <input type="hidden" name="amount" value={this.state.amount}/>
                                                    <input type="hidden" name="qty" value="1"/>
                                                    <input type="hidden" name="voucher_ki"
                                                    />

                                                    <fieldset>
                                                        <legend>Order Form - {this.state.fullTitle}
                                                        </legend>

                                                        <div
                                                            dangerouslySetInnerHTML={{__html: this.state.fullDescription}}></div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="firstName">First
                                                                Name</label>
                                                            <div className="controls">
                                                                <div
                                                                    className="input-prepend">
                                                                                                    <span
                                                                                                        className="add-on"><i
                                                                                                        className="icon-user"></i></span>
                                                                    <input type="text"
                                                                           id="firstName"
                                                                           name="firstName[firstName]"

                                                                           maxLength="255"
                                                                           placeholder="First Name"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="lastName">Last
                                                                Name</label>
                                                            <div className="controls">
                                                                <div className="input-prepend">
                                                                    <span className="add-on"><i
                                                                        className="icon-group"></i></span>
                                                                    <input type="text"
                                                                           id="lastName"
                                                                           name="tlastName"

                                                                           maxLength="255"
                                                                           placeholder="Last Name"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="email">Email
                                                                Address</label>
                                                            <div className="controls">
                                                                <div
                                                                    className="input-prepend">
                                                                        <span
                                                                            className="add-on"><i
                                                                            className="icon-envelope"></i></span>
                                                                    <input type="email"
                                                                           id="email"
                                                                           name="email"

                                                                           placeholder="Email Address"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="address_1">Address</label>
                                                            <div className="controls">
                                                                                                <textarea
                                                                                                    id="address_1"
                                                                                                    name="address_1"

                                                                                                    placeholder="Address"></textarea>
                                                            </div>
                                                            <p className="help-block"></p>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="address_2">Address 2</label>
                                                            <div className="controls">
                                                                    <textarea
                                                                        id="address_2"
                                                                        name="address_2"

                                                                        placeholder="Address"></textarea>
                                                            </div>
                                                            <p className="help-block"></p>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="postcode">City</label>
                                                            <div className="controls">
                                                                <div className="input-prepend">
                                                                            <span className="add-on">
                                                                                <i className="icon-map-marker"></i>
                                                                            </span>
                                                                    <input type="text"
                                                                           id="city"
                                                                           name="city"

                                                                           maxLength="20"
                                                                           placeholder="City"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="password">Password</label>
                                                            <div className="controls">
                                                                <div className="input-prepend">
                                                                        <span className="add-on">
                                                                            <i className="icon-key"></i>
                                                                        </span>
                                                                    <input type="password"
                                                                           autoComplete=""
                                                                           id="pass"
                                                                           name="pass"
                                                                           maxLength="255"
                                                                           placeholder="Password"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="password repeat">Password repeat</label>
                                                            <div className="controls">
                                                                <div
                                                                    className="input-prepend">
                                                                        <span className="add-on">
                                                                            <i className="icon-key"></i>
                                                                        </span>
                                                                    <input type="password"
                                                                           autoComplete=""
                                                                           id="pass2"
                                                                           name="pass2"

                                                                           maxLength="255"
                                                                           placeholder="Password"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="postcode">Post
                                                                Code</label>
                                                            <div className="controls">
                                                                <div className="input-prepend">
                                                                            <span className="add-on">
                                                                                <i className="icon-map-marker"></i>
                                                                            </span>
                                                                    <input type="text"
                                                                           id="postcode"
                                                                           name="postcode"
                                                                           maxLength="255"
                                                                           placeholder="Post Code"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label
                                                                className="control-label required"
                                                                htmlFor="phone">Phone
                                                                Number</label>
                                                            <div className="controls">
                                                                <div
                                                                    className="input-prepend">
                                                                        <span className="add-on">
                                                                            <i className="icon-phone"></i>
                                                                        </span>
                                                                    <input type="text"
                                                                           id="phone"
                                                                           name="phone"
                                                                           maxLength="255"
                                                                           placeholder="Phone Number"/>
                                                                </div>
                                                                <p className="help-block"></p>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <label className="control-label"
                                                                   htmlFor="singlebutton"></label>
                                                            <div className="controls">
                                                                <div className="mb-2">
                                                                    By clicking 'Order Now' you agree to our terms, as laid out in the <Link to="../disclaimer.html">Disclaimer</Link> and <Link to="../privacy.html">Terms and conditions</Link>.
                                                                </div>
                                                                <button
                                                                    id="singlebutton"
                                                                    name="singlebutton"
                                                                    onClick={this.validate.bind(this)}
                                                                    className="btn btn-primary">Order Now
                                                                </button>
                                                                <img src="./../images/Spinner-1.3s-38px.gif"
                                                                     alt="spinner" className="spinner-border"
                                                                     style={{display: this.state.displayNone}}/>
                                                            </div>
                                                        </div>

                                                        <div className="control-group">
                                                            <div className="row" id="payment-gateway-alert"
                                                                 style={{display: this.state.displayNone}}>
                                                                <div className="col-12 text-center">
                                                                    Please, wait till you are redirected to the payment
                                                                    gateway. Don't refresh the page or click any button.
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="sidebar" className="span4">
                                    <div className="row-fluid">
                                        <div className="span12 thumbnail text-block" id="thumbnail3">
                                            <img alt="300x200" src={ServiceImage}/>
                                            <div className="caption">
                                                <h3>
                                                    Service
                                                </h3>
                                                <p>
                                                    We are dedicated to ensuring that each of our customers enjoys a
                                                    superior service. All of our processes and documents have been
                                                    rigorously reviewed in order to ensure their accuracy and
                                                    validity.

                                                    We also provide all of our customers with free phone and email
                                                    support for any queries that they may have concerning any of our
                                                    products.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-fluid">
                                        <div className="span12">
                                            <p className="call-us-sidebar">Call us now on<br/><a id="contact-number"
                                                                                                 href="tel:08004402873">0800
                                                4402 873</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <SecondBox whyChooseUs={true} testimonials={true}/>
            </div>
        );
    }
}

export default OrderNow;