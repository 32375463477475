import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";


function EvictionNotice() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement’s Eviction Notices</title>
                            <meta id="meta-description" name="description" content="rovided you have the right documents and how to utilise them, then evicting a tenant is simple.
                                    Without this evicting a tenant is time consuming and expensive" />
                            <meta id="meta-keywords" name="keywords" content="Eviction Notice Form, Eviction Notice Forms, Section 8 Eviction Notice, S8 Eviction Notice,
                                Section 21 Eviction Notice, S21 Eviction Notice, Evict a Tenant, How to Evict a Tenant,
                                Evict a Tenant Quickly, How to Evict a Tenant Quickly, Evict a Tenant Fast, Evict a Tenancy
                                Cheaply, How to Evict a Tenant Cheaply
                                " />
                            <meta id="og-title" property="og:title" content="Tenant Agreement’s Eviction Notices" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">

                                            <legend>Eviction Notice
                                                <span className="pull-right">
                                                <Link to="/order-now/eviction_form.html" className="btn btn-primary">Order Now!</Link></span>
                                            </legend>

                                            <subhead>
                                                Section 8 Eviction Notice
                                            </subhead>
                                            <p>
                                                If your tenant has failed to pay you rent, damaged your property or
                                                breached your Tenancy Agreement in any other way, then <b>you are
                                                entitled to terminate their tenancy irrespective of the fixed term
                                                stated within your agreement.</b>
                                            </p>

                                            <p>
                                                In order to ensure that you are able to repossess your property as
                                                swiftly as possible, however, you must <b>ensure that you serve your
                                                tenant</b> with a Section 8 Eviction Notice and that this document
                                                clearly <b>states the precise breach that has taken place.</b> Get your
                                                Section 8 Eviction Notice and comprehensive guide detailing how to
                                                complete it
                                                <b>for just £9.99!</b>
                                            </p>

                                            <subhead>
                                                Section 21 Eviction Notice
                                            </subhead>

                                            <p>
                                                Provided you have the right documentation and know how to utilise it,
                                                then terminating a tenancy when the fixed term has expired or is close
                                                to expiration is a straightforward process. Without this, however, the
                                                process of evicting a tenant can be <b>both time consuming and
                                                expensive</b>.
                                            </p>

                                            <p>
                                                In order to ensure that you are able to <b>regain possession of your
                                                property as quickly as possible</b>, you must provide your tenant with
                                                a Section 21 Eviction Notice that is correctly written, provides your
                                                tenant with the minimum notice period required by law, and is addressed
                                                to
                                                the correct individual. Get your Section 21 Eviction Notice and
                                                comprehensive guide
                                                detailing how to complete it for <b>just £9.99!</b>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="../images/package3-img.jpg" />
                                            <div className="caption">
                                                <h3>
                                                    Want to Know More?
                                                </h3>
                                                <p>As well as an Eviction Notice our Landlord Pack includes other
                                                    essential documents such as a Guarantor Form, an Inventory form and
                                                    more.</p>
                                                <p className="text-center">
                                                    <Link to="/landlord-pack.html"
                                                                              className="btn btn-primary">Find Out
                                                    More!</Link></p>
                                            </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on <br /> <a id="contact-number"
                                                                                            href="tel:08004402873">0800
                                            4402 873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default EvictionNotice;