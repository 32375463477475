import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div>
            <footer >
                    <div className="container">
                        <div className="row-fluid">
                            <ul className="thumbnails">
                                <li className="span4">
                                    <strong className="footer-heading">3 Simple Steps</strong>
                                    <ul className="unstyled">
                                        <li><Link to="/step1.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-hand-right"></i>
                                    </span>Step 1</Link></li>
                                        <li><Link to="/step2.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-hand-right"></i>
                                    </span>Step 2</Link></li>
                                        <li><Link to="/step3.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-hand-right"></i>
                                    </span>Step 3</Link></li>
                                    </ul>
                                </li>
                                <li className="span4">
                                    <strong className="footer-heading">Social links</strong>
                                    <ul className="icons-ul">
                                        <li><a href="https://www.facebook.com/tenancyagreement1"><img
                                            src="../images/facebook-color.png" alt="Tenantcy agreement facebook page" /> Facebook</a></li>
                                        <li><a href="https://plus.google.com/108075629096708168229" rel="publisher"><img
                                            src="../images/gplus-color.png" alt="Google plus account" /> Google+</a></li>
                                        <li><a href="https://twitter.com/TenantAgreemnt"><img
                                            src="./images/twitter-color.png" alt="Twitter account" /> Twitter</a></li>
                                    </ul>
                                </li>
                                <li className="span4">
                                    <strong className="footer-heading">Site Links</strong>
                                    <ul className="unstyled">
                                        <li><Link to="/faq.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-signin"></i>
                                    </span>
                                            FAQs</Link></li>
                                        <li><Link to="/sitemap.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-signin"></i>
                                    </span>
                                            Sitemap</Link></li>
                                        <li><Link to="/privacy.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-signin"></i>
                                    </span>
                                            Terms and Conditions</Link></li>
                                        <li><Link to="/disclaimer.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-signin"></i>
                                    </span>
                                            Disclaimer</Link></li>
                                        <li><Link to="/guarantee.html">
                                    <span className="icon-stack">
                                        <i className="icon-check-empty icon-stack-base"></i>
                                        <i className="icon-signin"></i>
                                    </span>
                                            Guarantee</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
            </footer>
            <div>&copy; Tenant Agreement 1999 - 2021 </div>
        </div>
    );
}

export default Footer;