import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function CheckedService() {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement - Personal Service</title>
                            <meta id="meta-description" name="description" content="A completed Tenancy Agreement that sets out your and your tenants responsibilities,
                                checked for discrepancies, drafted for you and sent to you within 24 hours." />
                            <meta id="meta-keywords" name="keywords" content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement,
                                Checked Tenancy Agreement, Checked assured shorthold agreement,
                                Checked assured shorthold tenancy agreement" />
                            <meta id="og-title" property="og:title" content="Tenant Agreement - Personal Service" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>Checked Service
                                                <span className="pull-right"><a href="/order-now/checked_service.html"
                                                                                className="btn btn-primary">Order Now!</a></span>
                                            </legend>

                                            <p>
                                                A completed Tenancy Agreement that clearly sets out your and your
                                                tenant(s) responsibilities, <b>checked for discrepancies</b>, drafted
                                                for you and <b>sent to you within 24 hours</b>.
                                            </p>
                                            <p>
                                                Whilst there is no legal requirement for landlords to provide their
                                                tenants with a written agreement – and you may, instead, rely on verbal
                                                agreements reached between you and your tenant(s) – failing to do so
                                                can cause significant problems, and lead to you accruing <b>unnecessary
                                                legal costs</b>, in the event of a dispute.
                                            </p>
                                            <p>You won’t need to instruct a solicitor or understand complex legal
                                                jargon, you simply need a few minutes in order to complete a short,
                                                straightforward online form – your Tenancy Agreement will then be
                                                completed for you, checked by an experienced case manager and made
                                                available via download or sent to you by email within 24 hours.</p>
                                            With our checked Tenancy Agreement you can enjoy the
                                            <b>peace of mind</b>
                                            that comes from knowing that you’re covered in the event of a dispute
                                            between yourself and your tenant(s) –
                                            <b>and all for just £14.99!</b>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="../images/call-center.jpg" />
                                            <div className="caption">
                                                <h3>
                                                    Want to Know More?
                                                </h3>
                                                <p>Want to know more about what a Tenancy Agreement is, what it does and why
                                                    having one could save you time and money? </p>
                                                <p className="text-center"><a href="/about.html"
                                                                              className="btn btn-primary">Find Out More!</a>
                                                </p>
                                            </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on<br /><a id="contact-number"
                                                                                            href="tel:08004402873">0800 4402
                                            873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default CheckedService;