import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import"../../styles/will.module.css"


function WillOffer() {
    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Will Offer</title>
                            <meta id="meta-description" name="description" content="Tenancy Agreements that are both comprehensive, legally binding and can be downloaded, along with other vital documents for landlords, in minutes" />
                            <meta id="meta-keywords" name="keywords" content="Tenancy Agreement, assured shorthold agreement, assured shorthold tenancy agreement, download tenancy agreement, tenancy agreement template" />
                            <meta id="og-title" property="og:title" content="Will Offer" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V">
                                        <div className="caption will_caption__2CudZ">
                                            <legend>Will Offer</legend>
                                            <b>Professional, Personalised Will just £10! (normally £39) - with free
                                                updates for life.</b>

                                            <p>If you are expecting a significant change in lifestyle you will probably
                                                want to make a new Will.
                                                Your Last Will and Testament is a vital legal document, so it is
                                                important that your Will is prepared correctly.
                                                Failure to do so could leave your benefactors to deal with complicated
                                                and expensive issues. Tenancy Agreement
                                                therefore advise you to choose a high-quality, professional service.</p>

                                            <p>We have an exclusive agreement with LifeTime Wills UK. As a Tenancy
                                                Agreement UK customer your Last Will and
                                                Testament can be completed online now for just £10 (normally £39)
                                                or £20 for mirrored Wills (normally £59). This quick, simple and
                                                affordable
                                                Will solution allows you to change your Will whenever you want, and as
                                                many times as you require at no additional cost..</p>

                                            <p>Be confident that your Will is absolutely safe and legally binding. Don’t
                                                go through
                                                the repeated trouble and expense of updating your Will when your
                                                personal circumstances
                                                change; LifeTime Wills UK is the only Will writing resource you will
                                                ever need. Why wait? Order now…</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="./images/call-center.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} />
        </div>
    );
}

export default WillOffer;