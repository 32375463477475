import React from "react";
import $ from 'jquery';
import "bootstrap";
import MetaTags from "react-meta-tags";
import SecondBox from "../../components/SecondBox";


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrValidDates: ['YY/DD/MM', 'YYMMDD', 'YYYYMMDD', 'YYYY-MM-DD'],
            iFormatDate: 0,
            sErrIsEmpty: " Field required.\n",
            sErrFormat: "Err!",
            sErrNotChoosen: " is not choosen.\n",
            sErrValidateTextMinLength1: "It must be at least ",
            sErrValidateTextMinLength2: " characters.\n",
            sErrValidateEmail: "It is not a valid e-mail address.\n",
            sErrName: "",
            sErrEmail: "",
            sErrLastName: "",
            sErrPhone: "",
            sErrBoxComment: "",
            sErrorMsg: "",
            sGeneralMsg: "",
        }
    }

    checkErrorMsgStatus() {
        return this.state.sErrName + this.state.sErrEmail + this.state.sErrLastName + this.state.sErrPhone + this.state.sErrBoxComment;
    }

    validateText(sText, sName, iMinLength, iMaxLength) {
        let sErrorTextMsg = "";
        sText = $.trim(sText);

        if (sText === "") // If empty
        {
            sErrorTextMsg = this.state.sErrIsEmpty;
        } else if (sText !== "") // else if to short or to long string
        {
            if (iMinLength !== null && sText.length < iMinLength) {
                sErrorTextMsg += this.state.sErrValidateTextMinLength1 + iMinLength + this.state.sErrValidateTextMinLength2;
            }
            if (iMaxLength !== null && sText.length > iMaxLength)
                sErrorTextMsg += this.state.sErrValidateTextMaxLength1 + iMaxLength + this.state.sErrValidateTextMaxLength2;
        }
        return sErrorTextMsg;
    }


    modalReport() {
        let messageReport = '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
            '<div class="modal-dialog" role="document">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<h5 class="modal-title" id="exampleModalLabel">Please, check the following:</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' +
            '</div>' +
            '<div class="modal-body">' + this.state.sGeneralMsg + '</div>' +
            '<div class="modal-footer">' +
            '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        $("body", window.parent.document).append(messageReport);
        window.$('#exampleModal').modal('show');
    }

    validateEmail(sEmailToCheck, sName) {
        /* emailChecker = verify a valid email */
        let sErrorEmailMsg = "";
        let emailChecker = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
        let sEmail = $.trim(sEmailToCheck);

        if (sEmail === "") {
            sErrorEmailMsg = this.state.sErrIsEmpty;
        } else if (sEmail !== "") {
            if ((!emailChecker.test(sEmailToCheck))) {
                sErrorEmailMsg = this.state.sErrValidateEmail;
            }
        }
        return sErrorEmailMsg;
    }

    // validate contact form before sending
    validate(e) {
        e.preventDefault();
        $(".modal").remove();
        this.setState({
            sErrName: this.validateText(e.target.firstName.value, "First Name", 2),
            sErrEmail: this.validateEmail(e.target.submit_by.value, "Email"),
            sErrLastName: this.validateText(e.target.lastName.value, "Last Name", 2),
            sErrPhone: this.validateText(e.target.phone.value, "Phone No", false),
            sErrBoxComment: this.validateText(e.target.commentbox.value, "Enquiry", 10)
        }, () => {
            if ($.trim(this.checkErrorMsgStatus()) !== "") {
                return false;
            } else {
                // Normally we would use return true, but now we just wanna say that everything is okay
                this.sendEmail();
            }
        });

    }

    // Send an email through our Email server
    sendEmail() {
        let parentThis = this;
        let endpoint = 'https://qds.quickie-divorce.com/api/client-lead';
        let objFrmMain = document.contact;

        // send customer request to QDS
        let workload = {
            'domain': 'tenant-agreement.co.uk',
            'email': objFrmMain.submit_by.value,
            'first_name': objFrmMain.firstName.value,
            'last_name': objFrmMain.lastName.value,
            'phone': objFrmMain.phone.value,
            'callback': null,
            'notes': objFrmMain.commentbox.value
        };
        console.log("entered");

        $.ajax({
            url: endpoint,
            method: "POST",
            data: workload
        }).fail(function () {
            console.log(1);
            parentThis.state.sGeneralMsg = "Probably the message could not be sent for unknown reasons! Please, try later and if the problem  persists contact the consumer service.";
            parentThis.modalReport();
        }).done(function (result) {
            if (result.success === true) {
                parentThis.state.sGeneralMsg = "Message sent. You will be contacted as soon as possible.";
                parentThis.modalReport();
                $("[name='contact']").trigger("reset");
            } else {
                parentThis.state.sGeneralMsg = "Message not sent for unknown reasons! Please, try later and if the problem  persists contact the consumer service.";
                parentThis.modalReport();
            }
        });
    }

    countChars(field_this) {
        if (field_this.target.value.length > 244) {
            this.setState({sErrBoxComment: "You have reached the chars limit!"});
        } else {
            this.setState({sErrBoxComment: ""});
        }
    }

    clearAll() {
        this.setState({
            sErrName: "",
            sErrEmail: "",
            sErrLastName: "",
            sErrPhone: "",
            sErrBoxComment: ""
        });
    }

    render() {
        return (
            <div>
                <section className="container">
                    <div className="row-fluid">
                        <div className="span12 content-container">
                            <MetaTags>
                                <title>Contact Tenant Agreement</title>
                                <meta id="meta-description" name="description"
                                      content="Contact Tenant Agreement today"/>
                                <meta id="meta-keywords" name="keywords"
                                      content="Contact Tenant Agreement, Contact Us"/>
                                <meta id="og-title" property="og:title" content="Contact Tenant Agreement"/>
                            </MetaTags>
                            <div className="row-fluid">
                                <div id="content" className="span8">
                                    <div className="row-fluid">
                                        <div className="span12 thumbnail text-block">
                                            <div className="inner-content">
                                                <form name="contact" method="post" action="" className="form-horizontal"
                                                      onSubmit={this.validate.bind(this)}>
                                                    <legend>Contact Us Via Email</legend>
                                                    <div className="control-group">
                                                        <label htmlFor="firstName" className="control-label required">Your first name
                                                        </label>
                                                        <div className="controls">
                                                            <div className="input-prepend">
                                                                <span className="add-on">
                                                                    <i className="icon-user"></i>
                                                                </span>
                                                                <input type="text" className="form-control form-control-sm"
                                                                       name="firstName" id="firstName" maxLength="50"
                                                                       aria-describedby="clientfirstName"
                                                                       placeholder="Enter your first name"/>
                                                            </div>
                                                            {
                                                                this.state.sErrName && (
                                                                    <div
                                                                        className="error-box p-1 ">{this.state.sErrName}</div>
                                                                )}
                                                        </div>
                                                    </div><div className="control-group">
                                                    <label htmlFor="fullname" className="control-label required">Your last name
                                                    </label>
                                                    <div className="controls">
                                                        <div className="input-prepend">
                                                                <span className="add-on">
                                                                    <i className="icon-user"></i>
                                                                </span>
                                                            <input type="text" className="form-control form-control-sm"
                                                                   name="lastName" id="lastName" maxLength="50"
                                                                   aria-describedby="clientlastName"
                                                                   placeholder="Enter your last name"/>
                                                        </div>
                                                        {
                                                            this.state.sErrLastName && (
                                                                <div
                                                                    className="error-box p-1 ">{this.state.sErrLastName}</div>
                                                            )}
                                                    </div>
                                                </div>
                                                    <div className="control-group">
                                                        <label className="control-label required"
                                                               htmlFor="tenant_bundle_frontendbundle_contacttype_email">Email
                                                            Address</label>
                                                        <div className="controls">
                                                            <div className="input-prepend">
                                                                <span className="add-on"><i
                                                                    className="icon-envelope"></i></span>
                                                                <input type="email"
                                                                       id="submit_by"
                                                                       name="submit_by" placeholder="Email Address" />
                                                            </div>
                                                            {
                                                                this.state.sErrEmail && (
                                                                    <div
                                                                        className="error-box p-1">{this.state.sErrEmail}</div>
                                                                )}
                                                            <p className="help-block"></p>
                                                        </div>
                                                    </div>

                                                    <div className="control-group">
                                                        <label className="control-label required"
                                                               htmlFor="tenant_bundle_frontendbundle_contacttype_phone">Phone
                                                            Number</label>
                                                        <div className="controls">
                                                            <div className="input-prepend">
                                                                <span className="add-on"><i className="icon-phone"></i></span>
                                                                <input type="text"
                                                                       id="phone"
                                                                       name="phone[phone]"
                                                                        maxLength="255"
                                                                       placeholder="Phone Number" />

                                                            </div>
                                                            {
                                                                this.state.sErrPhone && (
                                                                    <div
                                                                        className="error-box p-1">{this.state.sErrPhone}</div>
                                                                )}
                                                            <p className="help-block"></p>
                                                        </div>
                                                    </div>
                                                    <div className="control-group">
                                                        <label className="control-label required"
                                                               htmlFor="exampleFormControlTextarea1">Enquiry</label>
                                                        <div className="controls">
                                                            <textarea
                                                                id="exampleFormControlTextarea1"
                                                                onKeyUp={(e) => this.countChars(e)} maxLength="246"
                                                                name="commentbox" placeholder="Enquiry"></textarea>
                                                            {
                                                                this.state.sErrBoxComment && (
                                                                    <div
                                                                        className="error-box p-1">{this.state.sErrBoxComment}</div>
                                                                )}
                                                        </div>
                                                        <p className="help-block"></p>
                                                    </div>
                                                    <div className="control-group">
                                                        <label className="control-label" htmlFor="singlebutton"></label>
                                                        <div className="controls">
                                                            <button id="singlebutton" name="singlebutton"
                                                                    className="btn btn-primary">Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="call-us">
                                                    <p className="call-us">Or Call Us On: <a id="contact-number"
                                                                                             href="tel:08004402873">
                                                        0800 4402 873</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="sidebar" className="span4">
                                    <div className="row-fluid">
                                        <div id="privacy_policy" className="span12 thumbnail text-block">
                                            <div id="privacy_policy_inner" className="inner-content">
                                                <fieldset>
                                                    <legend>Privacy Policy</legend>
                                                    <ul>
                                                        <li>Tenancy Agreement is committed to protecting
                                                            the identify and privacy of our users.
                                                        </li>
                                                        <li>We will only use information collected in the
                                                            process of transactions in a strictly lawful manner.
                                                        </li>
                                                        <li>Tenancy Agreement will not use client emails to
                                                            contact clients once the transaction has been
                                                            completed other than in accordance with a query from
                                                            them.
                                                        </li>
                                                        <li>Email addresses will not be provided to any
                                                            third party.
                                                        </li>
                                                    </ul>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <SecondBox whyChooseUs={true} testimonials={true}/>
            </div>
        );
    }
}

export default Contact;