import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";
import "../../styles/will.module.css"
import {Link} from "react-router-dom";


function Step3() {
    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>How to Download a Tenancy Agreement – Step 3</title>
                            <meta id="meta-description" name="description"
                                  content="Your Tenancy Agreement can be prepared for you within a matter of minutes with Tenant Agreement -
                                find out how here
                                "/>
                            <meta id="meta-keywords" name="keywords"
                                  content="Tenancy Agreement , assured shorthold agreement, assured shorthold tenancy agreement, download
                                    tenancy agreement, download a tenancy agreement, how to download a Tenancy Agreement
                            "/>
                            <meta id="og-title" property="og:title" content="How to Download a Tenancy Agreement – Step 3"/>
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V">
                                        <div className="caption">
                                            <legend>Step 3
                                                <span className="pull-right">
                                                    <Link className="btn btn-small" to="/step2.html">
                                                        <i className="icon-chevron-left"></i> Previous </Link>
                                                </span>
                                            </legend>
                                            <p>When you receive your Tenancy Agreement, both you and your tenant will
                                                need to sign the document in the presence of a witness. You and your
                                                tenant/s will then have a binding contract.</p>

                                            <p>Click <b><Link to="/services.html">here</Link></b> to start making your
                                                Tenancy Agreement</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V" id="thumbnail3">
                                        <img alt="300x200" src="../images/call-center.jpg"/>
                                        <div className="caption will_caption__2CudZ">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default Step3;