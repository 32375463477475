import React from "react";
import MetaTags from "react-meta-tags";
import "../../styles/about.module.css"
import {SecondBox} from "../../components";

function About() {
    return (
        <div>
            <section className="container">
                <MetaTags>
                    <title>About Tenant Agreement</title>
                    <meta id="meta-description" name="description"
                          content="Tenant Agreement was established in order to make the process of obtaining a legally-binding
                                Tenancy Agreement as quick, simple and affordable as possible/Tenancy Agreements should be
                                readily available, legally-binding and inexpensive – it is this belief that led to the
                                formation of Tenancy Agreement"/>
                    <meta id="meta-keywords" name="keywords"
                          content="About Tenant Agreement"/>
                    <meta id="og-title" property="og:title" content="About Tenant Agreement"/>
                </MetaTags>
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span6 thumbnail text-block about_text-block__1Un4d" id="thumbnail1">
                                        <img alt="300x200" src="./images/group-lawyer.jpg"/>
                                        <div className="caption">
                                            <h3>
                                                Expertise
                                            </h3>
                                            <p>
                                                Tenancy Agreement was established by a group of legal experts
                                                committed to making the process of obtaining a legally-binding
                                                Tenancy Agreements as quick, simple and affordable as possible.

                                                Each of our clients can rest assured that all the documents that we
                                                prepare are totally accurate and legally correct.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="span6 thumbnail text-block " id="thumbnail2">
                                        <img alt="300x200" src="./images/terrace-houses.jpg"/>
                                        <div className="caption">
                                            <h3>
                                                Experience
                                            </h3>
                                            <p>
                                                Tenancy Agreement have been offering low-cost, binding tenancy
                                                agreements since 2005 and are firmly established as a reputable and
                                                respected provider of legal documentation. Having prepared thousands
                                                of documents, you can rest-assured that we possess the knowledge
                                                required to prepare comprehensive and binding agreements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200" src="./images/call-center.jpg"/>
                                        <div className="caption">
                                            <h3>
                                                Service
                                            </h3>
                                            <p>
                                                We are dedicated to ensuring that each of our customers enjoys a
                                                superior service. All of our processes and documents have been
                                                rigorously reviewed in order to ensure their accuracy and validity.

                                                We also provide all of our customers with free phone and email
                                                support for any queries that they may have concerning any of our
                                                products.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true} list={false}/>
        </div>
    );
}

export default About;