import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useState, useEffect} from "react";
import {Footer, Header} from "./components";
import $ from "jquery";
import About from "./containers/About/About";
import AboutUs from "./containers/About/AboutUs";
import Home from "./containers/Home/Home";
import Services from "./containers/Services/Services";
import Testimonials from "./containers/Testimonials/Testimonials";
import Guarantee from "./containers/Guarantee/Guarantee";
import Faq from "./containers/Faq/Faq";
import WillOffer from "./containers/Wills/WillOffer";
import PersonalService from "./containers/PersonalService/PersonalService";
import Contact from "./containers/Contact/Contact";
import OrderNow from "./containers/OrderNow/OrderNow";
import Disclaimer from "./containers/Disclaimer/Disclaimer";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import Sitemap from "./containers/Sitemap/Sitemap";
import LandLordPack from "./containers/Services/LandLordPack";
import CheckedService from "./containers/Services/CheckedService";
import DownloadService from "./containers/Services/DownloadService";
import Step1 from "./containers/Steps/Step1";
import Step2 from "./containers/Steps/Step2";
import Step3 from "./containers/Steps/Step3";
import LandlordInventory from "./containers/Services/LandlordInventory";
import TdsNotice from "./containers/Services/TdsNotice";
import TenantGuarantor from "./containers/Services/tenant-guarantor";
import EvictionNotice from "./containers/Services/EvictionNotice";
import LodgerAgreement from "./containers/Services/LodgerAgreement";


$(document).ready(function () {

    // It works when menu for mobile devices is showing. When clicking to any link on the menu the menu will hide afterward
    $(".nav li a").on("click", function () {
        $(".nav li").removeClass("active");
        $(this).parent().addClass("active");
        $(this).parent().parent().parent(".dropdown").addClass("active");
        $(".dropdown-menu").removeClass("dropdown-menu-mobile");
    });

    // mobile css menu settings
    $(".menu-mobile").on("click", function (e) {
        $(".navbar-responsive-collapse").toggleClass("show-navbar-responsive-short");
        $(".navbar-responsive-collapse").removeClass("show-navbar-responsive-long");
    });

    // extend services sub menu
    $(".dropdown-toggle").on("click", function () {
        $(".navbar-responsive-collapse").toggleClass("show-navbar-responsive-long");

        if ($(".navbar-responsive-collapse").hasClass("show-navbar-responsive-long")) {
            $(".dropdown-menu").addClass("dropdown-menu-mobile");
        } else {
            $(".dropdown-menu").removeClass("dropdown-menu-mobile");
        }
    });

    // shorten services submenu
    $(".navbar-responsive-collapse li a").not(".dropdown-toggle").on("click", function () {
        $(".navbar-responsive-collapse").removeClass("show-navbar-responsive-long");
        $(".navbar-responsive-collapse").removeClass("show-navbar-responsive-short");
    });
});

function App() {
    const [headerTitle, setHeaderTitle] = useState({
        headerTitleBanner: "Low Cost Tenancy Agreements!"
    });

    // set to default value the upper blue title on the banner
    useEffect(() => {

        // change title on the header of services pages
        $("a, Link").on("click", function () {
            var headerTitle = $(this).attr("data-url") ?? "Low Cost Tenancy Agreements!";
            setHeaderTitle({
                headerTitleBanner: headerTitle
            });
        });

        // highlight with grey background the menu item related to the page
        let urlLink = window.location.pathname;
        if (urlLink !== "/") {
            $(".nav-collapse ul.nav li a[href*='" + urlLink + "']").parent().not(".dropdown").addClass("active");
            $("li.dropdown ul li a[href='" + urlLink + "']").parent().parent().parent().addClass("active");
        } else {
            $(".nav-collapse ul.nav li:nth-child(1)").not(".media-icons").addClass("active");
        }

    }, []);

    // "home" menu item selected
    const homeMenuLinkSelect = () => {
        $(".nav-collapse ul.nav li").removeClass("active");
        $(".nav-collapse ul.nav li:nth-child(1)").not(".media-icons").addClass("active");
    };

    return (
        <div className="App">
            <Router>
                <Header titleHeaderBanner={headerTitle.headerTitleBanner} homeLinkSelect={homeMenuLinkSelect}/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/about-us.html" exact component={() => <AboutUs/>}/>
                    <Route path="/about.html" exact component={() => <About/>}/>
                    <Route path="/services.html" exact component={() => <Services/>}/>
                    <Route path="/testimonials.html" exact component={() => <Testimonials/>}/>
                    <Route path="/guarantee.html" exact component={() => <Guarantee/>}/>
                    <Route path="/faq.html" exact component={() => <Faq/>}/>
                    <Route path="/will-offer.html" exact component={() => <WillOffer/>}/>
                    <Route path="/contact-us.html" exact component={() => <Contact/>}/>
                    <Route path="/personal-service.html" exact component={() => <PersonalService/>}/>
                    <Route path="/contact.html" exact component={() => <Contact/>}/>
                    <Route path="/order-now/:package_id" exact component={OrderNow}/>
                    <Route path="/disclaimer.html" exact component={() => <Disclaimer/>}/>
                    <Route path="/privacy.html" exact component={() => <PrivacyPolicy/>}/>
                    <Route path="/sitemap.html" exact component={() => <Sitemap/>}/>
                    <Route path="/landlord-pack.html" exact component={() => <LandLordPack/>}/>
                    <Route path="/checked-service.html" exact component={() => <CheckedService/>}/>
                    <Route path="/download-service.html" exact component={() => <DownloadService/>}/>
                    <Route path="/landlord-inventory.html" exact component={() => <LandlordInventory/>}/>
                    <Route path="/tds-notice.html" exact component={() => <TdsNotice/>}/>
                    <Route path="/tenant-guarantor.html" exact component={() => <TenantGuarantor/>}/>
                    <Route path="/eviction-notice.html" exact component={() => <EvictionNotice/>}/>
                    <Route path="/lodger-agreement.html" exact component={() => <LodgerAgreement/>}/>
                    <Route path="/step1.html" exact component={() => <Step1/>}/>
                    <Route path="/step2.html" exact component={() => <Step2/>}/>
                    <Route path="/step3.html" exact component={() => <Step3/>}/>

                </Switch>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;