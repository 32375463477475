import React from "react";
import {Link} from "react-router-dom";
import "../scripts/main_banner_4.js";
import selecteServiceMenuItem from "../scripts/scripts.js";

function Header(props) {

    return (
        <header>
            <div className="container">
                <div className="row-fluid">
                    <div className="span12">
                        <div className="navbar">
                            <div className="navbar-inner">
                                <div className="container-fluid">

                                    <Link to="#/" className="btn btn-navbar menu-mobile" data-toggle="collapse">
                                        Menu
                                    </Link>

                                    <Link className="brand" onClick={props.homeLinkSelect} to="/" >Tenant Agreement</Link>
                                    <ul className="nav pull-right visible-phone visible-tablet">
                                        <li className="media-icons">
                                            <a href="https://www.facebook.com/tenancyagreement1">
                                                <i className="icon-facebook-sign"></i>
                                            </a>
                                        </li>
                                        <li className="media-icons">
                                            <a href="https://twitter.com/TenantAgreemnt">
                                                <i className="icon-twitter-sign"></i>
                                            </a>
                                        </li>
                                        <li className="media-icons">
                                            <a href="https://plus.google.com/108075629096708168229" rel="publisher">
                                                <i className="icon-google-plus-sign"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="nav-collapse collapse navbar-responsive-collapse">
                                        <ul className="nav">
                                            <li className="">
                                                <Link to="/">
                                                    <i className="icon-home icon-white"></i> Home
                                                </Link>
                                            </li>
                                            <li className="">
                                                <Link to="/about-us.html">
                                                    <i className="icon-user"></i> About Us
                                                </Link>
                                            </li>
                                            <li className="">
                                                <Link to="/testimonials.html"><i
                                                    className="icon-comment"></i> Testimonials</Link>
                                            </li>
                                            <li className="">
                                                <Link to="/contact-us.html"><i
                                                    className="icon-envelope"></i> Contact</Link>
                                            </li>
                                            <li className="">
                                                <a href="https://tracker.tenant-agreement.co.uk"><i
                                                    className="icon-key"></i> Login</a>
                                            </li>
                                            <li className="dropdown">
                                                <Link data-toggle="dropdown" className="dropdown-toggle" to="#"><i
                                                    className="icon-th"></i> Services<strong className="caret"></strong></Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link to="/services.html">Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/landlord-pack.html">Comprehensive Landlord
                                                            Pack</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/checked-service.html"
                                                              data-url="Low Cost Personal Service!">Personal
                                                            Service</Link>
                                                    </li>
                                                    <li className="divider">
                                                    </li>
                                                    <li className="nav-header">Will Testament</li>
                                                    <li>
                                                        <Link to="/will-offer.html">Offer</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul className="nav pull-right hidden-phone hidden-tablet">
                                            <li className="media-icons">
                                                <a href="https://www.facebook.com/tenancyagreement1">
                                                    <i className="icon-facebook-sign"></i>
                                                </a>
                                            </li>
                                            <li className="media-icons">
                                                <a href="https://twitter.com/TenantAgreemnt">
                                                    <i className="icon-twitter-sign"></i>
                                                </a>
                                            </li>
                                            <li className="media-icons">
                                                <a href="https://plus.google.com/108075629096708168229" rel="publisher">
                                                    <i className="icon-google-plus-sign"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hero-unit">
                            <div className="row-fluid">
                                <div className="span8 hero-bg">
                                    <Link to="/">
                                        <img className="logo" src="../images/logo.png" alt="logo"/>
                                    </Link>
                                    <h1 data-title="Enter Heading" data-type="text">
                                        {props.titleHeaderBanner} </h1>
                                    <p data-title="Enter Tagline" className="tagline">
                                        Order your Tenancy Agreement today! </p>
                                    <p className="hero-action">
                                        <Link id="hero-btn" to="/services.html"  onClick={selecteServiceMenuItem}
                                              className="btn btn-red btn-large hero-btn">
                                            Learn more <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                                <div className="span4 hidden-phone hidden-small-screen">
                                    <div className="callus">
                                        <i className="icon-phone icon-rotate-90"></i>
                                        <div className="call-text">
                                            <span className="top">call us now on</span>
                                            <span className="phonenumber"><a
                                                href="tel:08004402873">0800 4402 873</a></span>
                                        </div>
                                    </div>
                                    <div className="hero-bubbles">
                                        <ul className="packages-grid">
                                            <li className="top">
                                                <Link to="/landlord-pack.html" onClick={selecteServiceMenuItem}>
                                                    <div className="img-base"></div>
                                                    <div className="img-overlay"></div>
                                                    <img src="../images/landlordtext3.png" alt="LandLord"/>
                                                </Link>
                                            </li>
                                            <li className="mid">
                                                <Link to="/checked-service.html" onClick={selecteServiceMenuItem} data-url="Low Cost Personal Service!">
                                                    <div className="img-base"></div>
                                                    <div className="img-overlay"></div>
                                                    <img src="../images/checkedservicetext3.png"
                                                         alt="Checked Service text"/>
                                                </Link>
                                            </li>
                                            <li className="bottom">
                                                <Link to="/download-service.html" onClick={selecteServiceMenuItem} data-url="Low Cost Download Service!">
                                                    <div className="img-base"></div>
                                                    <div className="img-overlay"></div>
                                                    <img src="../images/3minuteservicetext3.png"
                                                         alt="3 minute service"/>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;