import React from "react";
import SecondBox from "../../components/SecondBox";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";
import "../../styles/will.module.css"


function Sitemap() {

    return (
        <div>
            <div className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement – Sitemap</title>
                            <meta id="meta-description" name="description" content="Tenant Agreement Sitemap" />
                            <meta id="meta-keywords" name="keywords" content="Tenant Agreement Sitemap" />
                            <meta id="og-title" property="og:title" content="Tenant Agreement – Sitemap" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span6 thumbnail text-block will_text-block__1Ic-V" id="thumbnail1">
                                        <div className="caption">
                                            <h3>
                                                About
                                            </h3>
                                        </div>
                                        <ul>
                                            <li><Link to="/about.html">About Tenant Agreements</Link></li>
                                            <li><Link to="/about-us.html">About Us</Link></li>
                                            <li><Link to="/faq.html">FAQ</Link></li>
                                            <li><Link to="/guarantee.html">Guarantee</Link></li>
                                            <li><Link to="/privacy.html">Privacy Policy</Link></li>
                                            <li><Link to="/disclaimer.html">Disclaimer</Link></li>
                                            <li><Link to="/testimonials.html">Testimonials</Link></li>
                                            <li><Link to="/contact-us.html">Contact Us</Link></li>
                                        </ul>
                                    </div>
                                    <div className="span6 thumbnail text-block will_text-block__1Ic-V" id="thumbnail1">
                                        <div className="caption">
                                            <h3>
                                                Services
                                            </h3>
                                        </div>
                                        <ul>
                                            <li><Link to="/services.html">Services</Link></li>
                                            <li><Link to="/landlord-pack.html" data-url="">Landlord Pack</Link></li>
                                            <li><Link to="/download-service.html" data-url="Low Cost Download Service!">Download Service</Link></li>
                                            <li><Link to="/checked-service.html" data-url="Low Cost Personal Service!">Personal Service</Link></li>
                                            <li><Link to="/landlord-inventory.html" data-url="Low Cost Inventory Form!">Landlord Inventory Form</Link></li>
                                            <li><Link to="/tds-notice.html" data-url="Low Cost Tenant Deposit Notice!">TDS Notice</Link></li>
                                            <li><Link to="/tenant-guarantor.html" data-url="Low Cost Guarantor Form!">Tenant Guarantor Form</Link></li>
                                            <li><Link to="/eviction-notice.html" data-url="Low Cost Eviction Notice!">Eviction Notice dd</Link></li>
                                            <li><Link to="/will-offer.html">Will Offer</Link></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block will_text-block__1Ic-V" id="thumbnail1">
                                        <div className="caption">
                                            <h3>
                                                3 Simple Steps
                                            </h3>
                                        </div>
                                        <ul>
                                            <li><Link to="/step1.html">Step 1</Link></li>
                                            <li><Link to="/step2.html">Step 2</Link></li>
                                            <li><Link to="/step3.html">Step 3</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row-fluid">

                                </div>
                            </div>
                        </div>
                    </div>
                    <SecondBox whyChooseUs={true} testimonials={true} />
                </div>
            </div>
        </div>
    );
}

export default Sitemap;