import React from "react";
import SecondBox from "../../components/SecondBox";
import MetaTags from "react-meta-tags";


function TdsNotice(props) {

    return (
        <div>
            <section className="container">
                <div className="row-fluid">
                    <div className="span12 content-container">
                        <MetaTags>
                            <title>Tenant Agreement’s Deposit Notice Form</title>
                            <meta id="meta-description" name="description" content="All deposits that you collect from your tenants must be protected or you'll face
                                    a substantial fine. With our form, you can be sure that you’re protected" />
                            <meta id="meta-keywords" name="keywords" content="Tenant Deposit Scheme, Information on Tenant Deposit Schemes, Tenant Deposits,
                                Rental Deposits, What do I do with my Tenant’s Deposit, Documents for Landlords" />
                            <meta id="og-title" property="og:title" content="Tenant Agreement’s Deposit Notice Form" />
                        </MetaTags>
                        <div className="row-fluid">
                            <div id="content" className="span8">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block">
                                        <div className="caption">
                                            <legend>Section 213 Tenant Deposit Notice
                                                <span className="pull-right">
                <a href="/order-now/tds_notice_form.html" className="btn btn-primary">Order Now!</a></span>
                                            </legend>

                                            <p>
                                                Any deposit that you collect from your tenants <b>must be protected</b>.
                                                Furthermore, you must inform your tenant of how you have protected
                                                their deposit within 30 days of you having received it. Failing to do
                                                so will not only prevent you from regaining possession of your property
                                                following your agreement having expired, but is also likely to result
                                                in you being issued with a <b>substantial fine</b>.
                                            </p>

                                            <p>
                                                Tenancy Agreement can provide you with a Tenancy Deposit Protection
                                                Form (also known as a Section 213 Notice) – which details the deposit
                                                scheme that you are using, how your tenant can apply for their deposit
                                                to be released and what to do in the event of a dispute regarding the
                                                deposit – along with <b>detailed guidance notes</b> to help you
                                                complete the form correctly.
                                            </p>

                                            <p>
                                                With our Tenancy Deposit Protection form you can be sure you’ve <b>complied
                                                with the relevant legislation</b> thus ensuring that you’re able to,
                                                if necessary, evict your tenant and avoid unnecessary fines – <b>and
                                                all for just £4.99</b>!
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar" className="span4">
                                <div className="row-fluid">
                                    <div className="span12 thumbnail text-block" id="thumbnail3">
                                        <img alt="300x200"
                                             src="../images/package3-img.jpg" />
                                        <div className="caption">
                                            <h3>
                                                Want to Know More?
                                            </h3>
                                            <p>As well as a Tenant Deposit Scheme Form, our Landlord
                                                Pack includes other essential documents such as a
                                                Guarantor Form, Eviction Notices and more. </p>
                                            <p className="text-center"><a href="/landlord-pack.html"
                                                                          className="btn btn-primary">Find
                                                Out More!</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-fluid">
                                    <div className="span12">
                                        <p className="call-us-sidebar">Call us now on<br /><a id="contact-number"
                                                                                            href="tel:08004402873">0800
                                            4402 873</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SecondBox whyChooseUs={true} testimonials={true}/>
        </div>
    );
}

export default TdsNotice;