import React from "react";
import {Link} from "react-router-dom";
import listServices from "../files/listServices.json";
import listWhyChooseUs from "../files/whyChooseUs.json";
import testimonials from "../files/testimonial.json";
import solicitor from "../images/solicitor.png";
import WomanPointing from "../images/woman-pointing.png";
import selecteServiceMenuItem from "../scripts/scripts.js";

function SecondBox(props) {

    const listService = listServices.map((item, index) =>
        <tr id={index} key={index}>
            <td><Link to={item.urlPageDescription} onClick={selecteServiceMenuItem}><strong>{item.title}</strong></Link> <br />
                <small>{item.description}</small>
            </td>
            <td>{item.amount}</td>
            <td>
                <Link className="btn btn-success" onClick={selecteServiceMenuItem} to={/order-now/ + item.url}
                      id="btn0"><i className="icon-shopping-cart icon-white"></i></Link>
            </td>
        </tr>
    );

    const listWhyChoose = listWhyChooseUs.map((item, index) =>
        <li id={index} key={index}>
            <i className="icon-check"></i>
            <b> {item.title}</b><br/> - {item.subtitle}
        </li>
    );

    let randomNumber = Math.floor(Math.random() * (testimonials.length - 2));

    // find only two random testimonials
    const testimonialsList = testimonials.map((testimonial, index ) => {
            if(index >= randomNumber && index < (randomNumber + 2)) {
                return <div key={index}
                            className={testimonial.id % 2 ? "media testimonial-odd" : "media testimonial-even"}>
                    <img src={solicitor}
                         className="media-object pull-left img-polaroid client-img" alt="avatar"/>
                    <div className="media-body">
                        <strong>{testimonial.name}</strong>, {testimonial.location}
                        <blockquote>{testimonial.testimony}</blockquote>
                    </div>
                </div>
            } else {
                return [];
            }
        }
    );

    return (
        <section className="container">
            <div className="row-fluid">
                <div className="span12 content-container">
                    <div className="row-fluid">
                        <div className="span3 hidden-phone">
                            <img src={WomanPointing} className="woman-pointing" alt="woman pointing" />
                        </div>

                        <div id="variant" className="span9">
                            <div className="row-fluid why-us">

                            {props.list ?
                                <table className="table table-striped table-bordered" id="formTenancyList">
                                    <thead>
                                        <tr>
                                            <th>Tenancy Agreements Forms Packages</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listService}
                                    </tbody>
                                </table>
                            : ''}

                            {props.whyChooseUs ?
                                <div className="span6 well pull-left">
                                <h3>Why Choose Us</h3>
                                <ul className="unstyled">
                                    {listWhyChoose}
                                </ul>
                            </div>
                            : ''}

                            {props.mostOrderProduct ?
                            <div className="span6 well">
                                <h3>Most ordered product</h3>
                                <div className="media">
                                    <a href="/download-service.html" className="pull-left"> <i
                                        className="icon-gift icon-4x"></i>
                                    </a>
                                    <div className="media-body">
                                        <h4 className="media-heading">Download Service</h4>
                                        <blockquote>A Concise, Downloadable Tenancy Agreement</blockquote>
                                    </div>
                                </div>
                                <div className="media">
                                    <a href="/checked-service.html" className="pull-left"> <i
                                        className="icon-gift icon-4x"></i>
                                    </a>
                                    <div className="media-body">
                                        <h4 className="media-heading">Personal Service</h4>
                                        <blockquote>A Tenancy Agreement Checked for Discrepancies</blockquote>
                                    </div>
                                </div>
                                <div className="media">
                                    <a href="/landlord-pack.html" className="pull-left"> <i
                                        className="icon-gift icon-4x"></i>
                                    </a>
                                    <div className="media-body">
                                        <h4 className="media-heading">Comprehensive Landlord Pack</h4>
                                        <blockquote>A Checked Tenancy Agreement and Other Essential Documents</blockquote>
                                    </div>
                                </div>
                            </div>
                            : ''}

                            {props.testimonials ?
                                <div className="span6 well">
                                    <legend>What our Clients Say</legend>
                                    {testimonialsList}
                                </div>
                                : ''}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SecondBox;